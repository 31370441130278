import request from '@/api/request'

export function getList() {
    return request({
        url: '/oil/getCompanyList',
        method: 'get'
    })
}
export function getOilBillList(companyName, startDate) {
    return request({
        url: '/oil/getOilBill?companyName=' + companyName + '&startDate=' + startDate,
        method: 'get'
    })
}

export function exportOilBill(companyName, startDate) {
    return request({
        url: '/oil/exportOilBill?companyName=' + companyName + '&startDate=' + startDate,
        method: 'get',
        responseType: 'blob'
    })
}

export function search(ship) {
    return request({
        url: '/oil/search?ship=' + ship,
        method: 'get'
    })
}