<template>
    <div>
        <page-notice-list :list="list" @edit="editPageNotice" @load="onload"></page-notice-list>
        <van-popup
            v-model="show"
            @closed="closePop"
            closeable
            position="bottom"
            class="pop-cls"
            get-container="body">
            <van-loading type="spinner" v-show="popload"/>
            <van-cell-group v-if="!popload">
                <van-field
                    v-model="editInfo.name"
                    required
                    label="标题"
                    placeholder="请输入标题"
                />
              <quill-editor @change="onEditorChange" :options="editorOption" v-model="editInfo.content"></quill-editor>
<!--              <van-field-->
<!--                    v-model="editInfo.content"-->
<!--                    label="内容"-->
<!--                    rows="3"-->
<!--                    autosize-->
<!--                    type="textarea"-->
<!--                    placeholder="请输入内容"-->
<!--                />-->
                <van-cell-group>
                    <van-cell title="是否开启消息">
                        <van-switch v-model="editInfo.isopen" checked="true" active-value="1" inactive-value="0" />
                    </van-cell>
                </van-cell-group>

                <div style="margin: 16px;">
                    <van-button
                        :loading="btnDis"
                        :disabled="btnDis"
                        round
                        block
                        type="info"
                        @click="subBtn"
                    >
                    提交
                    </van-button>
                </div>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
    import PageNoticeList from '../components/PageNoticeList.vue'
    import {
        Loading,
        Popup,
        Field,
        CellGroup,
        Button,
        Toast,
        Switch, Cell
    } from 'vant'
    import notice from '../api/notice'
    import { quillEditor } from 'vue-quill-editor'
    export default {
        components: {
            VanPopup: Popup,
            VanLoading: Loading,
            VanField: Field,
            VanCellGroup: CellGroup,
            VanButton: Button,
            PageNoticeList: PageNoticeList,
            VanSwitch: Switch,
            VanCell: Cell,
            quillEditor
        },
        data() {
            return {
                show: false,
                popload: false,
                btnDis: false,
                editInfo: {
                    id: '',
                    name: '',
                    content: '',
                    isopen: '0'
                },
                defIndx: '',
                active: '',
                tabList: [],
                list: [],
                showPicker: false,
                load: true,
              editorOption: {
                placeholder: "请输入内容",
              },
            }
        },
        pageNum: 1,
        pageSize: 10,
        created() {
            this.$store.commit('SET_APPINFO', {
                navTitle: '首页公告',
                rightText: '新增',
                rightClick: this.addBtn,
            })
            this.loadData()
        },
        methods: {
            onload(callback) {
                this.nextlist(callback)
            },
            nextlist(callback) {
                this.pageNum++
                notice
                    .list(this.pageNum, this.pageSize, null)
                    .then((res) => {
                        if (res) {
                            console.log('list::', this.list, ',111')
                            this.list = this.list.concat(res)
                        }
                        callback &&
                        callback(res==null||res == 0)
                    })
            },
          onEditorChange({ editor, html, text }) {
            this.editInfo.content = html;
          },
            editPageNotice(id) {
                console.log(id)
                this.showPop()
                this.popload = true
                notice.getPageNoticeById(id).then((item) => {
                    this.editInfo.id = item.id
                    this.editInfo.name = item.name
                    this.editInfo.content = item.content
                    this.editInfo.isopen = item.isopen
                    this.popload = false
                })
            },
            addBtn() {
                this.editInfo.id = ''
                this.editInfo.name = ''
                this.editInfo.content = ''
                this.editInfo.isopen = '0'
                this.showPop()
            },
            showPop() {
                this.show = true
                this.saveStatus = false
            },
            subBtn() {
                this.btnDis = true
                if (this.editInfo.name.trim() != "" && this.editInfo.content) {
                    notice
                        .saveOrUpdateNotice(
                            this.editInfo.name,
                            this.editInfo.content,
                            this.editInfo.isopen,
                            this.editInfo.id
                        )
                        .then((res) => {
                            this.show = false
                            this.saveStatus = true
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.btnDis = false
                            }, 300)
                        })
                    return
                }
                Toast('请填写信息')
                this.btnDis = false;
            },
            closePop() {
                if (this.saveStatus) {
                    this.changeTab()
                }
            },
            changeTab() {
                this.loadData()
            },
            loadData() {
                this.list = []
                this.load = true
                this.pageNum = 1
                notice.list().then((res) => {
                    this.list = res
                    this.load = false
                })
            },
        },
      computed: {
        editor() {
          return this.$refs.myQuillEditor.quill;
        },
      }
    }
</script>

<style scoped lang="scss">
    .pop-cls {
        height: 65%;
        padding-top: 50px;
    }
</style>
