<template>
  <div>
    <div v-if="list != null && list.length > 0">
      <van-address-list
        @click-item="choose"
        v-model="chosenId"
        :list="list"
        default-tag-text="选中"
      ></van-address-list>
      <div>
        <van-field label="数量" placeholder="请输入数量" v-model="amount" type="number"></van-field>
        <van-button type="primary" round @click="updateApply">确认</van-button>
      </div>
    </div>
    <div v-else>
      <van-empty/>
    </div>
  </div>
</template>

<script>
import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  CellGroup,
  Search,
  Field,
  Col,
  Row,
  Image as VanImage,
  ImagePreview,
  Popup,
  Toast,
  Notify,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem,
  Loading,
  Dialog,
  AddressList,
} from 'vant'
import {findLockOils, findLockById, changeOilNumber} from "@/api/lockOil";

export default {
  name: "LockOilList",
  components: {
    VanEmpty: Empty,
    // VanPullRefresh: PullRefresh,
    // VanCell: Cell,
    VanButton: Button,
    VanField: Field,
    VanAddressList: AddressList,
    // VanNotify: Notify
  },
  data() {
    return {
      id: null,
      companyId: null,
      oilStationId: null,
      list: [],
      isLoading: false,
      chosenId: null,
      maxAmount: null,
      lockOilId: null,
      amount: null,
      itemId: null,
      oldLockOilId: null,
      numberPriceId: null,
    }
  },
  methods: {
    choose(item, index) {
      this.maxAmount = item.nowAmount;
      this.lockOilId = item.id;
    },
    initData() {
      findLockById(this.id).then(res => {
        if (res.resultCode == 0) {
          res.data.map(item => {
            let temp = {};
            temp.id = item.id;
            if (item.id == this.chosenId) {
              temp.isDefault = true;
              item.nowAmount = parseFloat(this.$route.query.amount) + parseFloat(item.nowAmount);
              this.maxAmount = item.nowAmount;
            }
            temp.name = item.goodsName;
            temp.nowAmount = item.nowAmount;
            temp.amount = item.amount;
            temp.tel = "余量：" + item.nowAmount + "/总量：" + item.amount + "（" + item.unit + "）";
            temp.address = "采购价格" + item.price + "/出售价格：" + item.salePrice
            this.list.push(temp);
          })
        }
      })
    },
    updateApply() {
      console.log(this.amount)
      console.log(this.maxAmount)

      if (this.amount != null && this.amount != "" && this.amount > this.maxAmount) {
        Notify({ type: 'warning', message: '超过总量限制' });
        return;
      }
      changeOilNumber(this.numberPriceId, this.oldLockOilId, this.itemId, this.lockOilId, this.amount).then(res => {
        console.log(res)
        if (res.resultCode == 0) {
          Notify({ type: 'success', message: '修改成功' });
          this.$router.go(-1);
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.chosenId = this.$route.query.sid;
    this.oldLockOilId = this.chosenId;
    this.itemId = this.$route.query.tid;
    this.numberPriceId = this.$route.query.subitemId;
    this.initData();
    // console.log(this.id)
    // console.log(this.companyId);
    // console.log(this.oilStationId);
    // console.log(this.list);
    // console.log(this.isLoading);
    // console.log(this.chosenId);
    // console.log(this.maxAmount);
    // console.log(this.lockOilId);
    // console.log(this.amount);
    // console.log(this.itemId)
    // console.log(this.oldLockOilId)
    // console.log(this.numberPriceId);
  }
}
</script>

<style scoped>
.van-address-list {
  padding: 12px;
}

.van-address-list__bottom {
  display: none;
}

.van-address-item__edit {
  display: none;
}
</style>
