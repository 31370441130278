var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-pull-refresh',{attrs:{"success-text":"刷新成功"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',[(_vm.list && _vm.list.length == 0)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e(),_c('van-collapse',{attrs:{"accordion":""},on:{"change":_vm.changeList},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('van-collapse-item',{key:item.id,attrs:{"title":'对账单，' + item.createTime,"name":index}},[(!_vm.details[item.id] || _vm.details[item.id].length == 0)?_c('van-loading',{attrs:{"type":"spinner"}}):_vm._e(),_c('van-checkbox-group',{model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},_vm._l((_vm.details[item.id]),function(subitem){return _c('van-cell-group',{key:subitem.id,attrs:{"title":_vm.tojosn(subitem.params2, 'shipName,planDate')}},[(_vm.ustatus == 1 || _vm.ostatus == 1)?_c('van-checkbox',{attrs:{"name":subitem.id}},[_vm._v("结算")]):_vm._e(),_vm._l((_vm.tojosn(subitem.content)),function(oilitem){return _c('van-cell',{key:oilitem.id,attrs:{"title":oilitem.goodsName +
                    '(' +
                    (_vm.ostatus == '' ? oilitem.salePrice : oilitem.price) +
                    '元/吨)',"value":oilitem.amount + oilitem.unit}})}),_c('van-divider',[_vm._v("单证编号:"+_vm._s(_vm.tojosn(subitem.params2, "docCode")))]),_c('van-image',{attrs:{"fit":"cover","width":"10rem","height":"10rem","lazy-load":"","src":_vm.tojosn(subitem.params2, 'docUrl')},on:{"click":function($event){_vm.showImg(_vm.tojosn(subitem.params2, 'docUrl'))}}})],2)}),1),(
              _vm.details[item.id] &&
                _vm.details[item.id].length > 0 &&
                (_vm.ustatus == 1 || _vm.ostatus == 1)
            )?_c('van-button',{attrs:{"plain":"","hairline":"","type":"primary"},on:{"click":function($event){return _vm.successCom(item.id, index)}}},[_vm._v("结算")]):_vm._e()],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }