<template>
  <div class="minWidth">
    <van-form @submit="getOilBill">
      <van-field
        readonly
        clickable
        label="公司名称"
        :value="companyName"
        placeholder="请选择公司名称"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          v-model="companyName"
          :columns="companyList"
          :loading="loading"
          title="请选择公司"
          @confirm="onConfirm"
          @cancel="showPicker = false"
          show-toolbar
        />
      </van-popup>
      <van-field
        readonly
        clickable
        label="结算月份"
        :value="startDate"
        placeholder="请选择月份"
        @click="showStartDate = true"
      />
      <van-popup v-model="showStartDate" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="请选择月份"
          @cancel="cancelStartDate"
          @confirm="getStartDate"
          :columns-order="['year', 'month']"
          :formatter="formatter"
          :min-date="minDate"
          :max-date="maxDate"
        />
      </van-popup>
      <div style="margin: 16px;">
        <van-button square block type="info" native-type="submit">
          搜索
        </van-button>
      </div>
    </van-form>
    <div style="overflow:auto">
      <table v-if="showTable" class="table minWidth">
        <!-- 至{{this.formatDate(endDate)}} -->
        <tr>
          <th colspan="13">{{this.companyName}}{{ this.startDate.substring(this.startDate.length - 2) }}月结算单</th>
        </tr>
        <tr>
          <th>
            序号
          </th>
          <th class="wspace">
            日期
          </th>
          <th class="wspace">
            订单编号
          </th>
          <th>
            结算方式
          </th>
          <th>
            加油站
          </th>
          <th>
            船名
          </th>
          <th>
            船舶识别号
          </th>
          <th>
            油品
          </th>
          <th>
            计量单位
          </th>
          <th>
            数量
          </th>
          <th>
            单价
          </th>
          <th>
            金额
          </th>
          <th>
            备注
          </th>
        </tr>
        <template v-for="item in oilBillList">
          <tr :key="index" v-for="(goodsBill,index) in item.goodsBillList">
            <td v-if="index==0" class="wspace" :rowspan="item.colspan">
              {{ item.index }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.planDate }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.oilApplyCode }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.paymentWay }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.oilStationName }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.shipName }}
            </td>
            <td v-if="index==0" class="wspace" :rowspan="item.goodsBillList.length">
              {{ item.shipChkid }}
            </td>
            <td class="wspace">
              {{ goodsBill.oilName }}
            </td>
            <td class="wspace">
              {{ goodsBill.unit }}
            </td>
            <td class="wspace textRight">
              {{ goodsBill.oilNumberAmount }}
            </td>
            <td class="wspace textRight">
              {{ goodsBill.salePrice }}
            </td>
            <td class="wspace textRight">
              {{ goodsBill.oilBillAmount }}
            </td>
            <td class="wspace">
              {{ goodsBill.remark }}
            </td>
          </tr>
          <tr :key="item.index" v-if="item.goodsBillList.length !== 1">
            <td :colspan="6">小计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {{ item.summary }}
            </td>
            <td></td>
          </tr>
        </template>
        <tr>
          <td colspan="7">
            合计
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
            {{ summary }}
          </td>
          <td>
          </td>
        </tr>
        <tr>
           <!-- colspan="5" rowspan="5" height="120px" -->
          <td colspan="5" height="120px">签字盖章</td>
          <!--  colspan="8" rowspan="5"  -->
          <td colspan="8" height="120px"></td>
        </tr>
      </table>
      <table border="0" v-if="showTable">
        <tr height="22px">
        </tr>
        <tr>
          <td width="250px"></td>
          <td>制单人：</td>
          <td width="280px"></td>
          <td>制单时间：</td>
          <td colspan="2">{{ billDate }}</td>
        </tr>
      </table>
    </div>
    <div style="margin: 16px;">
        <van-button v-if="showTable" round type="primary" @click="exportOilBill">
          导出
        </van-button>
      </div>
  </div>
</template>
<script>
import { getList, getOilBillList, exportOilBill } from '@/api/oilBill';
import { Form, Button, DatetimePicker, Picker, Popup, Field, Notify } from 'vant';
export default {
  name: 'OilBill',
  data() {
    return {
      companyList: [],
      companyName: '',
      startDate: '',
      endDate: '',
      oilBillList: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2100, 0, 1),
      loading: true,
      showPicker: false,
      showStartDate: false,
      // showEndDate: false,
      showTable: false,
      summary: 0.0,
      currentDate: '',
      billDate: ''
    }
  },
  created() {
    this.$store.commit('SET_APPINFO', {
      navTitle: '加油结算',
    });
    this.getCompanyList();
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
    this.currentDate = new Date(year + '-' + month);
    this.billDate = this.format(date) + day + '日'
  },
  methods: {
    getCompanyList() {
      getList().then(response => {
        this.companyList = response.data
      })
      this.loading = false;
    },
    getOilBill() {
      this.reset();
      if(this.compnayName === "" || this.startDate === "") {
        Notify({ type: 'danger', message: '公司名称或日期不得为空' })
        return;
      }
      getOilBillList(this.companyName, this.startDate).then(response => {
        this.summary = 0.0;
        this.oilBillList = response.data;
        if (this.oilBillList.length === 0) {
          Notify({ type: 'warning', message: '未查询到结算单' });
          this.showTable = false;
          return;
        }
        this.oilBillList.map(item => {
          if(item.goodsBillList.length !== 1) {
            item.colspan = item.goodsBillList.length + 1;
          } else {
            item.colspan = item.goodsBillList.length;
          }
          this.summary += parseFloat(item.summary);
        })
        this.summary = this.summary.toFixed(2);
        this.showTable = true;
      })
    },
    exportOilBill() {
      if(this.oilBillList.length === 0) {
        Notify({ type: 'danger', message: '数据为空，请重新选择' });
        return;
      }
      exportOilBill(this.companyName, this.startDate).then(res => {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href
        downloadElement.download = this.companyName + this.startDate.substring(this.startDate.length - 2) + '月结算单.xlsx'
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      });
    },
    onCancel() {
      this.showPicker = false;
    },
    onConfirm(value) {
      this.companyName = value;
      this.showPicker = false;
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    getStartDate(type) {
      this.startDate = this.format_(type);
      this.showStartDate = false;
    },
    cancelStartDate() {
      this.showStartDate = false;
    },
    formatDate(dateString) {
      let date = new Date(Date.parse(dateString.replace(/-/g,  "/")));
      return this.format(date);
    },
    format(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      return y + '年' + m + '月';
    },
    format_(type) {
      return type.getFullYear()+"-"+((type.getMonth()+1)>=10?+(type.getMonth()+1):"0"+(type.getMonth()+1))
    },
    reset() {
      this.oilBillList = [];
    },
  },
  components: {
    [Form.name]:Form,
    [DatetimePicker.name]:DatetimePicker,
    [Button.name]:Button,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [Field.name]:Field,
    [Notify.Component.name]: Notify.Component,
  }
}
</script>

<style scoped>
.minWidth {
  white-space: nowrap;
  min-width: 350px;
}
.table,
.table tr th,
.table tr td {
  border: 1px solid #000;
  border-collapse: collapse;
}
tr td {
  white-space: normal;
}
.wspace {
  white-space: nowrap;
}
.textRight {
  text-align: right;
}
</style>