// import axios from "common/js/http";
import request from '@/api/request'


const BASE_REQUEST = "/oil";

export function findLockOils(companyId, stationId) {
  const url = `${BASE_REQUEST}/findLockOils`;
  const data = {
    companyId,
    stationId
  }
  return request({
    url: url,
    data: data,
    method: 'post'
  }).then(res => {
    return Promise.resolve(res.data)
  })
}
export function findLockById(id) {
  const url = `${BASE_REQUEST}/findLockById`;
  const data = {
    id
  }
  return request({
    url: url,
    params: data,
    method: 'get'
  }).then(res => {
    return Promise.resolve(res.data)
  })
}

export function changeOilNumber(numberPriceId, oldLockOilId, applyId, lockOilId, amount) {
  const data = {
    numberPriceId,
    oldLockOilId,
    applyId,
    lockOilId,
    amount
  }
  const url = `${BASE_REQUEST}/changeOilNumber`;
  return request({
    url: url,
    data: data,
    method: "post"
  }).then(res => {
    return Promise.resolve(res.data);
  })
}
