const formatNumber = (n: number) => {
  const s = n.toString()
  return s[1] ? s : '0' + s
}

export const formatTime = (date: Date, jstr = '/') => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return (
    [year, month, day].map(formatNumber).join(jstr) +
    ' ' +
    [hour, minute, second].map(formatNumber).join(':')
  )
}

export const formatTimeYear = (date: Date, jstr = '/') => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join(jstr)
}

export const isWeixin=(): boolean => { //判断是否是微信
  let ua = navigator.userAgent.toLowerCase();
  return String(ua.match(/MicroMessenger/i)) == "micromessenger";
}