<template>
  <div class="home">
    <!-- <van-nav-bar left-text="返回" right-text="按钮" left-arrow></van-nav-bar> -->
    <van-loading type="spinner" v-if="loading" />
    <van-grid>
      <van-grid-item
        text="统计"
        icon="closed-eye"
        to="/sysdetil"
      ></van-grid-item>
      <van-grid-item
        v-for="item in list"
        :key="item.id"
        :icon="item.icon"
        :to="item.href"
        :text="item.name"
      />
      <van-grid-item
        v-if="userid"
        text="修改密码"
        icon="closed-eye"
        to="/uppwd"
      ></van-grid-item>
    </van-grid>
    <van-button type="danger" @click="loginout">退出登录</van-button>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Grid, GridItem, Button, Dialog, Loading,Toast } from 'vant'
import userApi from '../api/user'

export default {
  name: 'Home',
  data() {
    return {
      list: [],
      loading: true,
    }
  },
  computed: {
    userid() {
      return this.$store.getters.userInfo.id
    },
  },
  components: {
    // HelloWorld,
    VanButton: Button,
    VanGrid: Grid,
    VanGridItem: GridItem,
    VanLoading: Loading,
    // VanNavBar: NavBar,
  },
  created() {
    // console.log('123')
    this.$store.commit('SET_APPINFO', { navTitle: '首页' })
    // console.log('456')
    this.loadMenu()
  },
  methods: {
    loadMenu() {
      this.loading = true
      userApi
        .getUserRoleMenu()
        .then((res) => {
          // console.log(res)
          this.list = res
          this.loading = false
        })
        .catch((err) => {
          Dialog({ message: err })
        })
    },
    loginout() {
      Dialog.confirm({
        title: '退出登录',
        message: '是否确认',
      }).then(() => {
        Toast.loading({
          message: '退出中...',
          duration: 0,
          forbidClick: true,
        })
        // 退出登陆
        userApi.outLogin(this.$store.getters.userToken,this.$store.getters.userOpenid).then(()=>{
          Toast.clear()
         // on confirm
          this.$store.dispatch('setUserToken', '')
          this.$store.dispatch('setUserInfo', {})
          this.$router.replace('/login')
        })
        
       
      })
    },
  },
}
</script>
