











































































































































































































import {
  Field,
  CellGroup,
  PullRefresh,
  Cell,
  Search,
  Divider,
  Loading,
  Collapse,
  Toast,
  Empty,
  CollapseItem,
  Checkbox,
  Image as VanImage,
  ImagePreview,
  Button,
  CheckboxGroup,
  Col,
  Row,
} from 'vant'
import { Component, Prop, Vue } from 'vue-property-decorator'
import oilApi from '@/api/oil'
@Component({
  name: 'OilDocList',
  components: {
    VanPullRefresh: PullRefresh,
    VanCell: Cell,
    VanField: Field,
    VanEmpty: Empty,
    VanSearch: Search,
    VanCellGroup: CellGroup,
    VanLoading: Loading,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanDivider: Divider,
    VanButton: Button,
    VanCheckboxGroup: CheckboxGroup,
    VanCheckbox: Checkbox,
    VanCol: Col,
    VanImage: VanImage,
    VanRow: Row,
  },
})
export default class OilDocList extends Vue {
  @Prop() private code!: string
  private cname = ''
  private uname = ''
  /* eslint-disable */
  private list = [] as any
  private prices = {} as any
  private activeNames = '1'
  private result = [] as any
  private isLoading = false
  status = '4'
  ostatus = ''
  ustatus = ''
  private created(): void {
    // console.log('code', this.code)

    const sts = this.code.split(',')
    if (sts && sts.length > 1) {
      const s0 = sts[0].split(':')
      const s1 = sts[1].split(':')
      if (s0[0] == 's') {
        this.status = s0[1]
      }
      if (s1[0] == 'u') {
        this.ustatus = s1[1]
      }
      if (s1[0] == 'o') {
        this.ostatus = s1[1]
      }
    }
    this.loadData()
    // this.loadData(
    //   status,
    //   'payment',
    //   this.pageNo,
    //   this.pageSize,
    //   ustatus,
    //   ostatus
    // )
  }
  private checkAll(): void {
    ;(this.$refs.checkboxGroup as any).toggleAll(true)
  }
  private toggleAll(): void {
    ;(this.$refs.checkboxGroup as any).toggleAll(false)
  }
  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }
  // private clickSel(e: any): void {
  // e.preventDefault()
  // e.stopPropagation()
  // }
  private subFrom(): void {
    if (this.result && this.result.length > 0) {
      // 提示
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      oilApi
        .saveBillBean(this.result.join(','), this.ostatus === '' ? 1 : 2)
        .then((res: any) => {
          Toast.clear()
          if (res) {
            // 生成对账单
            // 刷新列表
            this.loadData()
            Toast('对账单生成')
          } else {
            Toast('未生成对账单')
          }
        })
        .catch(() => {
          Toast.clear()
          Toast('未生成对账单')
        })
    } else {
      Toast('未选择加油计划')
    }
    // console.log('sub', this.result)
  }
  private collChange(e: any): void {
    // 加载加油价格
    if (e && this.list && this.list.length >= e) {
      // 循环加载
      const index = Number(e) - 1
      const itid = this.list[index].id
      if (this.prices[itid] && Object.keys(this.prices[itid]).length) {
        // 已存在 忽略
      } else {
        // 查询
        this.$set(this.prices, itid, { loading: false, list: [] })
        oilApi.applyPrice(itid).then((res: any) => {
          this.prices[itid] = {
            loading: true,
            list: res,
          }
        })
      }
    }
  }
  private onSearch(): void {
    if (this.uname || this.cname) {
      // 根据名称查询
      this.loadData()
    }
  }
  private onRefresh(): void {
    this.loadData()
  }
  private loadData(): void {
    oilApi
      .queryOilApplyByDoc(
        this.status,
        'payment',
        this.ustatus,
        this.ostatus,
        this.uname,
        this.cname
      )
      .then((res: any) => {
        // console.log(res)
        this.isLoading = false
        this.list = res
        this.collChange(this.activeNames)
      })
      .catch(() => {
        this.isLoading = false
      })
  }
}
