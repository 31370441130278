<template>
  <div>
    <van-cell-group>
      <van-field
        v-model="pwd1"
        clearable
        label="新密码"
        @click-left-icon="showPwd1 = !showPwd1"
        :left-icon="showPwd1 ? 'browsing-history-o' : 'closed-eye'"
        :type="showPwd1 ? 'text' : 'password'"
        placeholder="请输入新密码"
      />
      <van-field
        v-model="pwd2"
        clearable
        label="确认新密码"
        @click-left-icon="showPwd2 = !showPwd2"
        :left-icon="showPwd2 ? 'browsing-history-o' : 'closed-eye'"
        :type="showPwd2 ? 'text' : 'password'"
        placeholder="请输入新密码"
      />
    </van-cell-group>
    <div v-show="pwd1 && pwd1.length < 6" style="color:red;">
      密码长度不能小于6位
    </div>
    <div v-show="pwd1 && checkStrong(pwd1) < 2">
      密码太简单，建议字母加数字组合
    </div>
    <div v-show="pwd2 && !isPwd" style="color:red;">两次输入密码不一致</div>
    <van-button
      color="linear-gradient(to right,  #4CD5A6,#5677fc)"
      size="normal"
      @click="uppwd"
    >
      修改密码
    </van-button>
  </div>
</template>

<script>
import { Field, Cell, Button, CellGroup, Toast } from 'vant'
import userApi from '../api/user'
export default {
  data() {
    return {
      pwd1: '',
      pwd2: '',
      showPwd1: false,
      showPwd2: false,
    }
  },
  computed: {
    isPwd() {
      return this.pwd1 === this.pwd2
    },
    isCheckPwd() {
      return this.checkStrong(this.pwd1)
    },
  },
  components: {
    VanField: Field,
    VanButton: Button,
    VanCellGroup: CellGroup,
  },
  created() {
    this.$store.commit('SET_APPINFO', { navTitle: '修改密码' })
  },
  methods: {
    checkStrong(sval) {
      let modes = 0
      if (sval.length < 1) return modes
      if (/\d/.test(sval)) modes++
      if (/[a-z]/.test(sval)) modes++
      if (/[A-Z]/.test(sval)) modes++
      if (/\W/.test(sval)) modes++
      if (modes > 2) {
        return sval.length < 12 ? 3 : 4
      }
      return modes
    },
    uppwd() {
      if (
        this.pwd1 &&
        this.pwd2 &&
        this.pwd1.length > 5 &&
        this.pwd1 === this.pwd2
      ) {
        if (this.checkStrong(this.pwd1) < 2) {
          Toast('密码太简单')
          return
        }
        console.log(this.pwd1, this.pwd2)
        userApi
          .uppwd(this.$store.getters.userInfo.id, this.pwd1)
          .then((res) => {
            if (res) {
              Toast('修改成功，请重新登录')
              this.$store.dispatch('setUserToken', '')
              this.$store.dispatch('setUserInfo', {})
              this.$router.replace('/login')
            } else {
              Toast('修改失败，请稍后再试')
            }
          })
      } else {
        Toast('两次输入密码不一致')
      }
      console.log('up')
    },
  },
}
</script>

<style></style>
