

















































































import oilApi from "@/api/oil";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Empty,
  PullRefresh,
  Toast,
  List,
  Cell,
  CellGroup,
  Collapse,
  Divider,
  CollapseItem,
  Image as VanImage,
  Checkbox,
  CheckboxGroup,
  Notify,
  Button,
  ImagePreview,
  Loading
} from "vant";
@Component({
  name: "BillChkList",
  components: {
    VanCell: Cell,
    VanList: List,
    VanButton: Button,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanCheckbox: Checkbox,
    VanCheckboxGroup: CheckboxGroup,
    VanEmpty: Empty,
    VanImage: VanImage,
    VanLoading: Loading,
    VanPullRefresh: PullRefresh,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem
  }
})
export default class BillChkList extends Vue {
  @Prop() private code!: string;
  private list = [] as any;
  private details = {} as any;
  private isLoading = false;
  private loading = false;
  private finished = false;
  private activeNames = 0;
  private oneBill = true;
  private status = "4";
  private ostatus = "";
  private ustatus = "";
  pageNo = 1;
  pageSize = 5;
  private created(): void {
    const sts = this.code.split(",");
    if (sts && sts.length > 1) {
      const s0 = sts[0].split(":");
      const s1 = sts[1].split(":");
      if (s0[0] == "s") {
        this.status = s0[1];
      }
      if (s1[0] == "u") {
        this.ustatus = s1[1];
      }
      if (s1[0] == "o") {
        this.ostatus = s1[1];
      }
    }
    this.oneBill = true;
    this.loadData();
  }
  private result = [];
  private changeList(e: string | number): void {
    // console.log('23', e)
    if (e === 0 || (e && e > 0)) {
      this.result = [];
      if (this.list && this.list[e] && this.list[e].id) {
        this.detail(this.list[e].id);
      }
    }
  }
  private tojosn(val: string, name: string) {
    if (!val) {
      return;
    }
    const v = JSON.parse(val);
    if (name) {
      const aname = name.split(",");
      if (aname && aname.length > 0) {
        let s = "";
        for (let i = 0; i < aname.length; i++) {
          if (s) {
            s += ",";
          }
          s += v[aname[i]];
        }
        return s;
      }
      return v[name];
    }
    return v;
  }

  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }

  private detail(id: string) {
    if (id && !this.details[id]) {
      oilApi.queryOilSubBill(id).then((res: any) => {
        // console.log(id, res, this.details)
        this.$set(this.details, id, res);
        // this.details[id] = res
      });
    }
    // console.log('object')
  }
  private successCom(id: string, index: number): void {
    if (this.result && this.result.length > 0) {
      Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true
      });
      oilApi
        .settleBill(id, this.result.join(","))
        .then((res: any) => {
          console.log(res);
          Toast.clear();
          if (res) {
            this.list.splice(index, 1);
            Toast("结算成功");
          } else {
            Toast("结算失败");
          }
        })
        .catch(() => {
          Toast.clear();
          Toast("结算失败");
        });
    } else {
      Notify("未选择结算计划");
      //   Toast('未选择结算计划')
    }
  }
  private onRefresh(): void {
    this.oneBill = true;
    // this.isLoading = false
    this.pageNo = 1;
    this.loadData();
  }
  private loadData(): void {
    oilApi
      .queryOilBill(
        this.ustatus || this.ostatus,
        this.ostatus == "" ? 1 : 2,
        this.pageNo,
        this.pageSize
      )
      .then((res: any) => {
        // console.log(res)
        this.loading = false;
        if (this.oneBill) {
          this.oneBill = false;
          this.isLoading = false;
          this.list = res;
          this.changeList(this.activeNames);
        } else {
          this.list = this.list.concat(res);
        }

        if (!res || (res && res.length == 0)) {
          //   console.log('finished')
          this.finished = true;
        }
      });
  }
  private onLoad() {
    this.pageNo++;
    this.loadData();
  }
}
