





























































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Form,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Tag,
  Loading,
  RadioGroup,
  Radio,
  Picker,
  DropdownMenu,
  DropdownItem,
  Image as VanImage,
  ImagePreview,
  Popup,
  NumberKeyboard,
  Toast,
  Collapse,
  CollapseItem,
} from 'vant'
import {Component, Prop, Vue} from 'vue-property-decorator'
import pubmessage from '../api/pubmessage'

@Component({
  name: 'PubMessageList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanTag: Tag,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem,
    VanPicker: Picker,
    VanForm: Form,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanNumberKeyboard: NumberKeyboard,
    VanPopup: Popup,
    VanLoading: Loading,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio
  },
})
export default class PubMessageList extends Vue {
  @Prop() private code!: string
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private disabled = false
  private title = ''
  private content = ''
  private content2 = ''
  private content3 = ''
  private content4 = ''
  private remarks = ''
  private shipStatus = ''
  private area = ''
  private type = 'oil_gzh_yijian'
  private list = [] as any
  private activeNames = ['1']
  private statusList = [] as any
  private typeList = [] as any
  pageNo = 1
  companyId = ''
  companyindex = 0
  id = ''
  isOne = true
  bbk = {} as any

  private created(): void {
    this.$store.commit('SET_APPINFO', {
      navTitle: '消息',
      rightText: '新增',
      rightClick: this.add,
    })
    this.showCode()
  }

  private onLoad(): void {
    this.pageNo++
    this.showCode()
  }

  private onFailed(errorInfo: string) {
      console.log('failed', errorInfo);
  }
  private cannelCom(id: string,index: number): void {
    pubmessage.getPubMessageById(id).then((res: any) => {
      if(res){
         this.title=res.title
         this.content=res.content
      }
    })
    this.id = id
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }

  private add(id: string): void {
    if (id != undefined) {
      this.disabled = true
    } else {
      this.disabled = false
    }
    this.show = true
    this.getPubMessageById(id)
  }

  private successCannelCom(): void {
    if (this.type == "oil_gzh_yijian" && this.content.trim() != "" && this.title.trim() != "" && this.remarks.trim() != "") {
      this.submit();
    } else if (this.type == "zswl_gzh_huopanbaojiacg" && this.title.trim() != "" && this.content.trim() != "" && this.content2.trim() != "" && this.content3.trim() != "" && this.content4.trim() != "" && this.remarks.trim() != "") {
      this.submit();
    } else if (this.content.trim() != "" && this.title.trim() != "" && this.type == "gzh_xitongyujingtongzhi" && this.content2.trim() != "" && this.remarks.trim() != "") {
      this.submit();
    } else {
      Toast.fail("数据没有填写完整！")
    }
  }

  private submit() {
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    pubmessage.saveOrUpdatePubMessage(this.title, this.content, this.content2, this.content3, this.content4, this.remarks, this.shipStatus, this.area, this.type).then((res: any) => {
      Toast.clear()
      this.list = []
      this.pageNo = 1
      this.showCode()
      this.show = false
      if (res) {
        this.title = ''
        this.content = ''
        this.content2 = ''
        this.content3 = ''
        this.content4 = ''
        this.area = ''
        this.shipStatus = ''
        this.remarks = ''
        this.id = ''
        this.type = 'oil_gzh_yijian'
      }
    })
  }
  private chexiao() {
    this.id = ''
    this.title = ''
    this.content = ''
    this.content2 = ''
    this.content3 = ''
    this.content4 = ''
    this.area = ''
    this.shipStatus = ''
    this.remarks = ''
    this.type = 'oil_gzh_yijian'
    this.show = false
  }

  private hideItem(index: number) {
    this.list.splice(index, 1)
  }

  private getPubMessageById(id: string): void {
    pubmessage.getPubMessageById(id).then((res: any) => {
      if (res.resultCode === "0") {
        this.typeList = res.typeList.map((item: any) =>{
          item.text = item.strValue2
          item.value = item.strValue1
          return item;
        });
        this.statusList = res.statusList;
        if (res.data != null) {
          this.id = res.data.id
          this.title = res.data.title
          this.content = res.data.content
          this.content2 = res.data.content2
          this.content3 = res.data.content3
          this.content4 = res.data.content4
          this.shipStatus = res.data.shipStatus
          this.area = res.data.area
          this.remarks = res.data.remarks
          this.type = res.data.type
        } else {
          this.id = ""
          this.title = ""
          this.content = ""
          this.content2 = ""
          this.content3 = ""
          this.content4 = ""
          this.shipStatus = ""
          this.area = ""
          this.remarks = ""
          this.type = "oil_gzh_yijian"
        }
      }
    });
  }

  private showCode(): void {
    pubmessage.getPubMessageList(this.pageNo, 5).then((res: any) => {
      if (res && res.list && res.list.length) {
        this.list = this.list.concat(res.list)
      } else {
        this.finished = true
      }

      this.isLoading = false
      this.loading = false

      if (this.isOne && this.list && this.list.length) {
        this.isOne = false
      }
    })
  }

  private onRefresh(): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.isOne = true
    this.showCode()
  }
}
