// import axios from "axios";
import { Toast } from "vant";
const axios= (window as any).axios
const BASE_REQUEST = "/credit";
const credit: any = {
    getCreditList(status: string, pageNum = 1, pageSize = 10,shipName='') {
    const url =  `${BASE_REQUEST}/querycreditBeans`;
    const data = {
      status,
      pageNum,
      pageSize,
      shipName
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.page);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getCreditBeanByOId(oid: string) {
    const url = `${BASE_REQUEST}/getCreditBeanByOId`;
    const data = {
        oid
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  saveOrUpdateCredit(
    oid: string,
    paymentDays: number,
    oilLines: number,
    discount: number,
    status: string,
    id: string
  ) {
    const data = {
        oid: oid,
        paymentDays: paymentDays,
        oilLines:oilLines,
        discount:discount,
        status:status,
        id:id
    };
    const url =`${BASE_REQUEST}/saveOrUpdateCredit`;
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  }
};
export default credit;
