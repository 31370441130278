// import axios from "axios";
import { Toast } from "vant";
const axios= (window as any).axios
const BASE_REQUEST = "/pubmessage";
const pubmessage: any = {
    getPubMessageList(pageNum = 1, pageSize = 10) {
    const url =  `${BASE_REQUEST}/queryPubMessagePage`;
    const data = {
      pageNum,
      pageSize
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.page);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getPubMessageById(id: string) {
    const url = `${BASE_REQUEST}/getPubMessageById`;
    const data = {
        id
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  saveOrUpdatePubMessage(
    title: string,
    content: string,
    content2: string,
    content3: string,
    content4: string,
    remarks: string,
    shipStatus: string,
    area: string,
    type: string
  ) {
    const data = {
      title: title,
      content:content,
      content2: content2,
      content3: content3,
      content4: content4,
      remarks: remarks,
      shipStatus: shipStatus,
      area: area,
      type: type,
    };
    const url =`${BASE_REQUEST}/savePubMessage`;
    return axios.post(url,data).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  }
};
export default pubmessage;
