<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="待授信" name="0">
        <credit-list :code="active"></credit-list>
      </van-tab>
      <van-tab title="已授信" name="1"
        ><credit-list :code="active"></credit-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import creditList from "../components/creditList.vue";
export default {
  name: "About",
  data() {
    return {
      active: 0
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "船舶授信" });
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    CreditList: creditList
  },
  methods: {
    tabChange(title, name) {
      // console.log(title, name)
    }
  }
};
</script>
