<template>
  <div class="about">
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
      @change="tabChange"
    >
      <van-tab title="审核中" name="0">
        <oil-chk-list :code="active"></oil-chk-list>
      </van-tab>
      <van-tab title="审核通过" name="1"
        ><oil-chk-list :code="active"></oil-chk-list
      ></van-tab>
      <van-tab title="审核驳回" name="2"
        ><oil-chk-list :code="active"></oil-chk-list
      ></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from "vant";
import OilChkList from "../components/OilChkList.vue";
export default {
  name: "About",
  data() {
    return {
      active: 0
    };
  },
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "加油审核" });
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    OilChkList: OilChkList
  },
  methods: {
    tabChange(title, name) {
      // console.log(title, name)
    }
  }
};
</script>
