






























































































































































































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Cell,
  Divider,
  Search,
  CellGroup,
  Field,
  Col,
  Row,
  Tag,
  Loading,
  Image as VanImage,
  ImagePreview,
  Popup,
  NumberKeyboard,
  Toast,
  Collapse,
  CollapseItem,
} from 'vant'
import {Component, Prop, Vue} from 'vue-property-decorator'
import oil from '../api/oil'

@Component({
  name: 'CompanyChkList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanSearch: Search,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanTag: Tag,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanNumberKeyboard: NumberKeyboard,
    VanPopup: Popup,
    VanLoading: Loading,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
  },
})
export default class CompanyChkList extends Vue {
  @Prop() private code!: string
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private showkeyb = false
  private cannelVal = ''
  private shipName = ''
  private fidnumber = '' // 出售价格
  private prinumber = '' // 采购价格
  private list = [] as any
  private prices = {} as any
  private activeNames = ['1']
  pageNo = 1
  companyId = ''
  companyindex = 0
  isOne = true
  bbk = {indx: 0, tid: ''} as any

  private created(): void {
    this.showCode()
  }

  private onLoad(): void {
    this.pageNo++
    this.showCode()
  }

  private onSearch() {
    this.onRefresh()
  }

  private downOil(id: string, name: string): void {
    Toast.loading({
      message: '下载中...',
      duration: 3000,
      forbidClick: true,
    })
    oil.downoil(id, name)
  }

  private ifLockFlag(index: number, tid: string, lockFlag: number, id: string, sid: string, amount: number, subitemId: string) {
    if (lockFlag != 1) {
      this.showKeybb(index, tid)
    } else {
      this.$router.push({
          path: "/lockOil",
          query: {
            id: id,
            tid: tid,
            sid: sid,
            amount: amount,
            subitemId: subitemId,
          }
        })
    }
  }

  private showKeybb(indx: number, tid: string) {
    console.log(1, indx, tid)
    // console.log(this.prices[tid]['list'][indx])
    this.bbk.indx = indx
    this.bbk.tid = tid
    console.log(2, this.bbk, this.prices[tid]['list'])
    this.fidnumber =
      this.prices[tid]['list'][indx].salePrice ||
      this.prices[tid]['list'][indx].salePricep
    this.prinumber =
      this.prices[tid]['list'][indx].price ||
      this.prices[tid]['list'][indx].pricep
    this.showkeyb = true
  }

  private closekb(): void {
    console.log(3, this.bbk)
    oil
      .upOilNumberPrice(
        this.prices[this.bbk.tid]['list'][this.bbk.indx].id,
        this.fidnumber,
        this.prinumber
      )
      .then((res: any) => {
        if (res) {
          this.prices[this.bbk.tid]['list'][
            this.bbk.indx
            ].salePrice = this.fidnumber
          this.prices[this.bbk.tid]['list'][
            this.bbk.indx
            ].price = this.prinumber
        } else {
          Toast('未修改')
        }
      })
      .catch(() => {
        Toast('修改失败')
      })
  }

  private collChange(e: any): void {
    // 加载加油价格
    // console.log(e, 'coll')
    if (e && e.length > 0) {
      // 循环加载
      for (let i = 0; i < e.length; i++) {
        const index = Number(e[i]) - 1
        if (index >= this.list.length) {
          continue
        }
        const itid = this.list[index].id
        // if (this.prices[itid] && Object.keys(this.prices[itid]).length) {
        //   // 已存在 忽略
        //   // console.log('wl', this.prices)
        //   continue
        // } else {
        // 查询
        // console.log('qu', this.prices)
        // this.prices[itid] = {
        //   loading: false,
        //   list: [],
        // }
        this.$set(this.prices, itid, {loading: false, list: []})
        // console.log('cq', this.prices)
        // console.log(this.list)
        oil.applyPrice(itid).then((res: any) => {
          // console.log(res)
          this.prices[itid] = {
            loading: true,
            list: res,
          }
        })
        // break
        // }
      }
    }
  }

  private cannelCom(id: string, index: number): void {
    this.companyId = id
    this.cannelVal = ''
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }

  private successCannelCom(): void {
    if (this.cannelVal) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      oil.updateApply(this.companyId, '2', this.cannelVal).then((res: any) => {
        Toast.clear()
        this.show = false
        if (res) {
          this.hideItem(this.companyindex)
        }
      })
    } else {
      // 未填写拒绝理由
      Toast.fail('未填写拒绝理由')
    }
  }

  private successCom(id: string, index: number): void {
    // 审核通过
    this.companyindex = index
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    })
    oil.updateApply(id, '1').then((res: any) => {
      if (res) {
        this.hideItem(this.companyindex)
        Toast.clear()
      } else {
        Toast.fail('数据有误！')
      }
    })
  }

  private hideItem(index: number) {
    this.list.splice(index, 1)
    this.collChange(this.activeNames)
  }

  private showImg(list: Array<string>, start = 0) {
    if (typeof list === 'string') {
      list = [list]
    }
    ImagePreview({
      images: list,
      startPosition: start,
      onClose() {
        // do something
      },
    })
  }

  private showCode(): void {
    oil
      .listApply(this.code, 'payment', this.pageNo, 5, this.shipName)
      .then((res: any) => {
        // console.log(res)
        this.list = this.list.concat(res.list)
        this.isLoading = false
        this.loading = false
        if (!res.list || res.list.length == 0) {
          this.finished = true
        }
        if (this.isOne && this.list && this.list.length) {
          this.isOne = false
          this.collChange(this.activeNames)
        }
      })
  }

  private onRefresh(): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.isOne = true
    this.showCode()
  }
}
