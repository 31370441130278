<template>
  <div>
  <van-dropdown-menu>
   <van-dropdown-item v-model="oilAreaId" @change="onchange"  :options="tabList" />
  </van-dropdown-menu>
        <oil-station-list
          :list="list"
          ref="mychild"
          @edit="editStation"
          @load="onload"
        ></oil-station-list>
    <van-popup
      v-model="show"
      @closed="closePop"
      closeable
      position="bottom"
      class="pop-cls"
      get-container="body"
    >
      <van-loading type="spinner" v-show="popload" />
      <van-cell-group v-if="!popload">
        <van-field
          v-model="editInfo.name"
          required
          label="加油站名称"
          placeholder="请输入名称"
        />
       <van-field
          v-model="editInfo.location"
          required
          label="地址"
          placeholder="请输入地址"
        />
        
       <van-field
          v-model="editInfo.phone"
          required
          label="联系电话"
          placeholder="请输入电话"
        />
        <van-field
          v-model="editInfo.longitude"
          label="经度"
          placeholder="请输入经度"
        /><van-field
          v-model="editInfo.latitude"
          label="纬度"
          placeholder="请输入纬度"
        />
        <van-field
          v-model="editInfo.remarks"
          label="备注"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入备注"
        />
        <div style="margin: 16px;">
          <van-button
            :loading="btnDis"
            :disabled="btnDis"
            round
            block
            type="info"
            @click="subBtn"
          >
            提交
          </van-button>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import OilStationList from '../components/OilStationList.vue'
import {
  Loading,
  Popup,
  Field,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Button,
  Toast
} from 'vant'
import oilArea from '../api/oilArea'
import oilStation from '../api/oilStation'
export default {
  components: {
    VanPopup: Popup,
    VanLoading: Loading,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanButton: Button,
    OilStationList: OilStationList,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem,
  },
  data() {
    return {
      show: false,
      popload: false,
      btnDis: false,
      editInfo: {
        id: '',
        name: '',
        location: '',
        phone: '',
        longitude: '',
        latitude: '',
        oilAreaId: '',
        companyId: '',
        remarks: '',
      },
      defIndx: '',
      active: '',
      tabList: [],
      list: [],
      showPicker: false,
      load: true,
      oilAreaId:'',
      companyId: '',
    }
  },
  pageNo: 1,
  pageSize: 10,
 
  created() {
    this.$store.commit('SET_APPINFO', {
      navTitle: '加油站管理',
      rightText: '新增',
      rightClick: this.addBtn,
    })
    this.oilAreaId=this.$route.query.oilAreaId
    this.companyId=this.$route.query.companyId
    this.loadData()
    this.loadArea()
  },
  methods: {
    onload(callback) {
      //   console.log('onload')
      this.nextlist(callback)
    },
    nextlist(callback) {
      this.pageNo++
      //   console.log('type', this.active)
      oilStation
        .list(
          this.oilAreaId, this.pageNo, this.pageSize)
        .then((res) => {
          if (res) {
            this.list = this.list.concat(res)
          }
          callback &&
            callback(res==null||res == 0)
        })
    },
    editStation(id) {
      this.showPop()
      this.popload = true
      oilStation.getOilstationById(id).then((item) => {
        this.editInfo.id = item.id
        this.editInfo.companyId = item.companyId
        this.editInfo.oilAreaId = item.oilAreaId
        this.editInfo.name = item.name
        this.editInfo.location = item.location
        this.editInfo.longitude = item.longitude
        this.editInfo.latitude = item.latitude
        this.editInfo.phone = item.phone
        this.editInfo.remarks = item.remarks
        this.popload = false
      })
    },
    addBtn() {
        this.editInfo.id = ''
        this.editInfo.companyId =''
        this.editInfo.oilAreaId = this.oilAreaId
        this.editInfo.name = ''
        this.editInfo.location = ''
        this.editInfo.longitude = ''
        this.editInfo.latitude = ''
        this.editInfo.phone = ''
        this.editInfo.remarks = ''
      this.showPop()
    },
    showPop() {
      this.show = true
      this.saveStatus = false
    },
    subBtn() {
      this.btnDis = true
      if (this.editInfo.name && this.editInfo.location&& this.editInfo.phone) {
        // console.log('sub', this.editInfo)
        oilStation
          .saveOrUpdateOilstation(
            this.editInfo.name,
            this.editInfo.phone,
            this.editInfo.location,
            this.editInfo.oilAreaId,
            this.editInfo.longitude,
            this.editInfo.latitude,
            this.editInfo.companyId,
            this.editInfo.remarks,
            this.editInfo.id
          )
          .then((res) => {
            this.show = false
            // console.log(res)
            this.saveStatus = true
          })
          .finally(() => {
            setTimeout(() => {
              this.btnDis = false
            }, 300)
          })
        return
      }
      Toast('请填写信息')
    },
    closePop() {
      if (this.saveStatus) {
        this.changeTab()
      }
    },
    changeTab() {
      this.loadData()
    },
    loadData() {
      this.list = []
      this.load = true
      this.pageNo = 1
     oilStation
        .list(
          this.oilAreaId, this.pageNo, this.pageSize).then((res) => {
        this.list = res
        this.load = false
      })
    },
    loadArea() {
      this.tabList = []
      this.pageNo = 1
      oilArea.list(this.companyId,this.pageNo,100).then((res) => {
        if(res){
            for(let data of res) {
                let marker = {
                     text: data.name,
                     value: data.id,
                  };
               this.tabList.push(marker);
            }
        }
      })
    },
    onchange(){
        this.loadData()
        this.$refs.mychild.onone()
    }
  },
}
</script>

<style scoped lang="scss">
.pop-cls {
  height: 40%;
  padding-top: 50px;
}
</style>
