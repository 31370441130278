<template>
  <div>
    <van-tabs v-model="active" @change="changeTab" color="#4CD5A6">
      <van-tab
        v-for="item in tabList"
        :key="item.id"
        :name="item.strValue1"
        :title="item.strValue2"
      >
        <!-- <van-loading type="spinner" v-show="load" /> -->
        <oil-goods-list
          :ref="'oilgoodslist' + item.strValue1"
          :type="item.strValue1"
          :list="list"
          @edit="editGoods"
          @load="onload"
        ></oil-goods-list>
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="show"
      @closed="closePop"
      closeable
      position="bottom"
      class="pop-cls"
      get-container="body"
    >
      <van-loading type="spinner" v-show="popload" />
      <van-cell-group v-if="!popload">
        <van-field
          v-model="editInfo.name"
          required
          label="商品名称"
          placeholder="请输入名称"
        />
        <van-field
          :value="editInfo.typeVal"
          required
          readonly
          clickable
          label="商品类型"
          @click="showPicker = true"
          placeholder="请选择类型"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :default-index="defIndx"
            :columns="tabList"
            value-key="strValue2"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>      
        <van-field required name="radio" label="是否可用">
          <template #input>
            <van-radio-group v-model="editInfo.delFlag" direction="horizontal">
              <van-radio name="0" >是</van-radio>
              <van-radio name="1">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="editInfo.remarks"
          label="备注"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入备注"
        />
        <van-uploader v-model="fileList" max-count="1" :after-read="afterRead" :before-delete="bedelete" upload-text="请上传图片"/>
        <div style="margin: 16px;">
          <van-button
            :loading="btnDis"
            :disabled="btnDis"
            round
            block
            type="info"
            @click="subBtn"
          >
            提交
          </van-button>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import OilGoodsList from '../components/OilGoodsList.vue'
import {
  Tab,
  Tabs,
  Loading,
  Popup,
  Field,
  CellGroup,
  Button,
  Toast,
  Picker,
  Uploader,
  RadioGroup,
  Radio,
} from 'vant'
import oilApi from '../api/oil'
import upload from '../api/upload'
export default {
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    VanPopup: Popup,
    VanLoading: Loading,
    VanPicker: Picker,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanButton: Button,
    VanUploader:Uploader,
    OilGoodsList: OilGoodsList,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
  },
  data() {
    return {
      show: false,
      popload: false,
      btnDis: false,
      editInfo: {
        id: '',
        name: '',
        type: '',
        typeVal: '',
        delFlag: '',
        remarks: '',
      },
      defIndx: '',
      active: '',
      tabList: [],
      list: [],
      showPicker: false,
      load: true,
      fileList: [],
    }
  },
  pageNo: 1,
  pageSize: 10,
  created() {
    this.$store.commit('SET_APPINFO', {
      navTitle: '加油商品管理',
      rightText: '新增',
      rightClick: this.addBtn,
    })
    this.loadData()
  },
  methods: {
    onload(callback) {
      //   console.log('onload')
      this.nextlist(callback)
    },
    nextlist(callback) {
      this.pageNo++
      //   console.log('type', this.active)
      oilApi
        .queryGoodsPage(this.active, this.pageNo, this.pageSize)
        .then((res) => {
          if (res.page && res.page.list) {
            this.list = this.list.concat(res.page.list)
          }
          callback &&
            callback(res.page == null || res.page.list == 0, this.active)
        })
    },
    onConfirm(value) {
      console.log(value)
      this.editInfo.typeVal = value.strValue2
      this.editInfo.type = value.strValue1
      this.showPicker = false
    },
    editGoods(id) {
      this.showPop()
      this.popload = true
      this.fileList=[]
      oilApi.getGoodsById(id).then((item) => {
        this.editInfo.id = item.id
        this.editInfo.name = item.name
        this.editInfo.type = item.type
        let dei = ''
        this.editInfo.typeVal = ''
        if (
          this.tabList.some((str, idxs) => {
            dei = idxs
            return str.strValue1 == item.type
          })
        ) {
          this.defIndx = dei
          this.editInfo.typeVal = this.tabList[dei].strValue2
        }
        this.editInfo.delFlag = item.delFlag
        this.editInfo.remarks = item.remarks
        this.editInfo.url = item.url
        if(item.url){
        let urls=[{url:item.url}]
        this.fileList=urls
        } 
        this.popload = false
        
      })
    },
    addBtn() {
      this.fileList=[]
      this.editInfo.id = ''
      this.editInfo.name = ''
      this.editInfo.type = ''
      this.editInfo.typeVal = ''
      this.editInfo.remarks = ''
      this.editInfo.url = ''
      this.showPop()
    },
    showPop() {
      this.show = true
      this.saveStatus = false
    },
    subBtn() {
      this.btnDis = true
      if (this.editInfo.type && this.editInfo.name) {
        // console.log('sub', this.editInfo)
        oilApi
          .saveOrUpdateGoods(
            this.editInfo.name,
            this.editInfo.type,
            this.editInfo.remarks,
            this.editInfo.id,
            this.editInfo.url,
            this.editInfo.delFlag
          )
          .then((res) => {
            this.show = false
            // console.log(res)
            this.saveStatus = true
          })
          .finally(() => {
            setTimeout(() => {
              this.btnDis = false
            }, 300)
          })
        return
      }
      Toast('请填写信息')
    },
    closePop() {
      if (this.saveStatus) {
        this.changeTab()
      }
    },
    changeTab() {
      this.loadData(this.active)
    },
    loadData(type = '') {
      this.list = []
      this.load = true
      this.pageNo = 1
      oilApi.queryGoodsPage(type).then((res) => {
        // console.log(res)
        this.tabList = res.list
        this.list = res.page.list
        this.load = false
      })
      if (type) {
        let typeref = 'oilgoodslist' + type
        this.$nextTick(() => {
          //   console.log(typeref, 'name', this.$refs, this.$refs[typeref])
          this.$refs[typeref][0].onone()
        })
      }
    },
     afterRead(file) {
      upload.uploadPic(file.file).then((item) => {
        this.editInfo.url=item
      })
    },
     bedelete(file) {
        this.editInfo.url=''
        return true
    },
  },
}
</script>

<style scoped lang="scss">
.pop-cls {
  height: 40%;
  padding-top: 40px;
}
</style>
