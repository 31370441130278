import { render, staticRenderFns } from "./OilDocList.vue?vue&type=template&id=355be56f&scoped=true&"
import script from "./OilDocList.vue?vue&type=script&lang=ts&"
export * from "./OilDocList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355be56f",
  null
  
)

export default component.exports