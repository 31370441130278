import { Toast } from 'vant'
const axios = (window as any).axios
const BASE_REQUEST = "/upload";
const upload: any = {
    uploadPic(file: any) {
    const url =  `${BASE_REQUEST}/uploadPic`;
    let config = {
      headers: {'Content-Type': 'multipart/form-data'}
    }
 let param = new FormData();
 param.append("file",file)
    return axios.post(url, param,config).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.url);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  }
}
export default upload;