<template>
  <div>
    <van-cell-group>
      <van-field
        v-model="value1"
        label="用户名"
        left-icon="smile-o"
        placeholder="用户名"
      />
      <van-field
        v-model="value2"
        clearable
        label="密码"
        @click-left-icon="showPwd = !showPwd"
        :left-icon="showPwd ? 'browsing-history-o' : 'closed-eye'"
        :type="showPwd ? 'text' : 'password'"
        placeholder="密码"
      />
    </van-cell-group>
    <van-button
      color="linear-gradient(to right, #5677fc, #4CD5A6)"
      size="normal"
      @click="login"
    >
      登录
    </van-button>
  </div>
</template>

<script>
import { Field, Button, CellGroup, Toast } from 'vant'
import { isWeixin } from '../common/utils/utils'

import userApi from '../api/user'
export default {
  data() {
    return {
      value1: '',
      value2: '',
      showPwd: false,
    }
  },
  components: {
    VanField: Field,
    VanButton: Button,
    VanCellGroup: CellGroup,
  },
  created() {
    this.$store.commit('SET_APPINFO', { navTitle: '登录' })
    if (this.loadToken()) {
      return
    }
    // 判断是否微信浏览器，openid 是否存在，不存在 获取，存在 判断是否已登陆
    if (isWeixin()) {
      // console.log('v1')
      if (this.$store.getters.userOpenid) {
        // 判断是否登陆
        this.loginByOpenid()
        return
      }
      if (this.$route.query.code) {
        // 换取openid
        this.$store.commit('SET_WXREDIRECTAUTHCODE', this.$route.query.code)
        this.$router.replace('/login')
        this.getOpenId()
        return
      }
      if (this.$store.getters.wxRedirectAuthCode) {
        // console.log('code', this.$store.getters.wxRedirectAuthCode)
        this.getOpenId()
        return
      }

      // 请求code 换取openid
      const appid = 'wx86f5c2c867337bf3'
      let ruri = encodeURIComponent(window.location.href)
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${ruri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirec`
      window.location.href = url
    }
  },
  methods: {
    getOpenId() {
      // 换取openid
      userApi
        .getOpenid(this.$store.getters.wxRedirectAuthCode, 'uMk09d12IO7')
        .then((res) => {
          if (res.resultCode === '0' && res.data) {
            this.$store.dispatch('setUserOpenid', res.data)
            // 判断是否登陆
            this.loginByOpenid(res.data)
          } else {
            Toast.fail('网络错误，请稍后再试')
          }
        })
    },
    loginByOpenid(openid) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      userApi
        .loginOpenid(openid || this.$store.getters.userOpenid)
        .then((res) => {
          Toast.clear()
          if (res.resultCode === '0' && res.gcb_token) {
            this.$store.dispatch('setUserToken', res.gcb_token)
            this.$store.dispatch('setUserInfo', res)
            this.$router.replace(this.$store.getters.rpath)
          }
        })
    },
    loadToken() {
      console.log(this.$store.getters.userToken)
      if (this.$store.getters.userToken) {
        this.$router.replace('/')
        return true
      }
      return false
    },
    login() {
      console.log(this.value1, this.value2)
      if (this.value1 && this.value2) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        userApi
          .login(this.value1, this.value2, this.$store.getters.userOpenid)
          .then((res) => {
            console.log(res)
            if (res.resultCode === '0') {
              Toast.clear()
              this.$store.dispatch('setUserToken', res.gcb_token)
              this.$store.dispatch('setUserInfo', res)
              this.$router.replace(this.$store.getters.rpath)
            } else {
              Toast.fail(res.resultMsg)
            }
          })
      } else {
        Toast.fail('填写账户和密码')
      }
    },
  },
}
</script>

<style></style>
