<template>
  <div>
  <van-dropdown-menu>
   <van-dropdown-item v-model="companyId" @change="onchange"  :options="tabList" />
  </van-dropdown-menu>
        <oil-area-list
          :list="list"
          ref="mychild"
          @edit="editArea"
          @load="onload"
        ></oil-area-list>
    <van-popup
      v-model="show"
      @closed="closePop"
      closeable
      position="bottom"
      class="pop-cls"
      get-container="body"
    >
      <van-loading type="spinner" v-show="popload" />
      <van-cell-group v-if="!popload">
        <van-field
          v-model="editInfo.name"
          required
          label="区域名称"
          placeholder="请输入名称"
        />
       <van-field
          v-model="editInfo.location"
          required
          label="地址"
          placeholder="请输入地址"
        />
        
       <van-field
          v-model="editInfo.telephone"
          required
          label="联系电话"
          placeholder="请输入电话"
        />
        <van-field
          v-model="editInfo.remarks"
          label="备注"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入备注"
        />
        <div style="margin: 16px;">
          <van-button
            :loading="btnDis"
            :disabled="btnDis"
            round
            block
            type="info"
            @click="subBtn"
          >
            提交
          </van-button>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import OilAreaList from '../components/OilAreaList.vue'
import {
  Loading,
  Popup,
  Field,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Button,
  Toast
} from 'vant'
import oilArea from '../api/oilArea'
import company from '../api/company'
export default {
  components: {
    VanPopup: Popup,
    VanLoading: Loading,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanButton: Button,
    OilAreaList: OilAreaList,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem,
  },
  data() {
    return {
      show: false,
      popload: false,
      btnDis: false,
      editInfo: {
        id: '',
        name: '',
        location: '',
        telephone: '',
        lipCompanyId: '',
        remarks: '',
      },
      defIndx: '',
      active: '',
      tabList: [],
      list: [],
      showPicker: false,
      load: true,
      companyId:'',
    }
  },
  pageNo: 1,
  pageSize: 10,
 
  created() {
    this.$store.commit('SET_APPINFO', {
      navTitle: '加油区域管理',
      rightText: '新增',
      rightClick: this.addBtn,
    })
    this.companyId=this.$route.query.companyId
    this.loadData()
    this.loadCompany()
  },
  methods: {
    onload(callback) {
      //   console.log('onload')
      this.nextlist(callback)
    },
    nextlist(callback) {
      this.pageNo++
      //   console.log('type', this.active)
      oilArea
        .list(
          this.companyId, this.pageNo, this.pageSize)
        .then((res) => {
          if (res) {
            this.list = this.list.concat(res)
          }
          callback &&
            callback(res==null||res == 0)
        })
    },
    editArea(id) {
      this.showPop()
      this.popload = true
      oilArea.getOilAreaById(id).then((item) => {
        this.editInfo.id = item.id
        this.editInfo.lipCompanyId = item.lipCompanyId
        this.editInfo.name = item.name
        this.editInfo.location = item.location
        this.editInfo.telephone = item.telephone
        this.editInfo.remarks = item.remarks
        this.popload = false
      })
    },
    addBtn() {
        this.editInfo.id = ''
        this.editInfo.lipCompanyId = this.companyId
        this.editInfo.name = ''
        this.editInfo.location = ''
        this.editInfo.telephone = ''
        this.editInfo.remarks = ''
      this.showPop()
    },
    showPop() {
      this.show = true
      this.saveStatus = false
    },
    subBtn() {
      this.btnDis = true
      if (this.editInfo.name && this.editInfo.location&& this.editInfo.telephone) {
        // console.log('sub', this.editInfo)
        oilArea
          .saveOrUpdateOilArea(
            this.editInfo.name,
            this.editInfo.telephone,
            this.editInfo.location,
            this.editInfo.lipCompanyId,
            this.editInfo.remarks,
            this.editInfo.id
          )
          .then((res) => {
            this.show = false
            // console.log(res)
            this.saveStatus = true
          })
          .finally(() => {
            setTimeout(() => {
              this.btnDis = false
            }, 300)
          })
        return
      }
      Toast('请填写信息')
    },
    closePop() {
      if (this.saveStatus) {
        this.changeTab()
      }
    },
    changeTab() {
      this.loadData()
    },
    loadData() {
      this.list = []
      this.load = true
      this.pageNo = 1
     oilArea
        .list(
          this.companyId, this.pageNo, this.pageSize).then((res) => {
        this.list = res
        this.load = false
      })
    },
    loadCompany() {
      this.tabList = []
      this.pageNo = 1
      company.list('',this.pageNo,100).then((res) => {
        if(res){
            for(let data of res) {
                let marker = {
                     text: data.companyName,
                     value: data.id,
                  };
               this.tabList.push(marker);
            }
        }
      })
    },
    onchange(){
        this.loadData()
        this.$refs.mychild.onone()
    }
  },
}
</script>

<style scoped lang="scss">
.pop-cls {
  height: 35%;
  padding-top: 50px;
}
</style>
