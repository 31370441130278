


























































































































































import {
  Empty,
  PullRefresh,
  Button,
  List,
  Form,
  Cell,
  Divider,
  CellGroup,
  Field,
  Col,
  Row,
  Tag,
  Search,
  Loading,
  RadioGroup,
  Radio,
  Image as VanImage,
  ImagePreview,
  Popup,
  NumberKeyboard,
  Toast,
  Collapse,
  CollapseItem,
} from 'vant'
import { Component, Prop, Vue } from 'vue-property-decorator'
import credit from '../api/credit'

@Component({
  name: 'creditList',
  components: {
    VanField: Field,
    VanEmpty: Empty,
    VanPullRefresh: PullRefresh,
    VanList: List,
    VanCell: Cell,
    VanTag: Tag,
    VanForm: Form,
    VanSearch: Search,
    VanDivider: Divider,
    VanCellGroup: CellGroup,
    VanImage: VanImage,
    VanButton: Button,
    VanCol: Col,
    VanRow: Row,
    VanNumberKeyboard: NumberKeyboard,
    VanPopup: Popup,
    VanLoading: Loading,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
  },
})
export default class CreditList extends Vue {
  @Prop() private code!: string
  private isLoading = false
  private loading = false
  private finished = false
  private show = false
  private paymentDays = ''
  private oilLines = ''
  private discount = ''
  private shipName = ''
  private list = [] as any
  private activeNames = ['1']
  private pattern = /^[1-9][0-9]*([.][0-9]{0,2})?$/
  private radio = '1'
  private status = '1'
  pageNo = 1
  companyId = ''
  companyindex = 0
  oid = ''
  id = ''
  isOne = true
  bbk = {} as any
  private created(): void {
    this.showCode()
  }
  private onLoad(): void {
    this.pageNo++
    this.showCode()
  }
  private onSearch() {
    this.onRefresh()
  }
  private onFailed(errorInfo: string) {
    console.log('failed', errorInfo)
  }
  private cannelCom(id: string, oid: string, index: number): void {
    credit.getCreditBeanByOId(oid).then((res: any) => {
      if (res) {
        this.paymentDays = res.paymentDays
        this.discount = res.discount
        this.oilLines = res.oilLines
        if (res.status) {
          this.status = res.status
        }
      }
    })
    this.id = id
    this.oid = oid
    this.companyindex = index
    // 审核拒绝
    // 填写拒绝理由
    this.show = true
  }
  private successCannelCom(): void {
    if (this.paymentDays && this.discount && this.oilLines) {
      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      })
      credit
        .saveOrUpdateCredit(
          this.oid,
          this.paymentDays,
          this.oilLines,
          this.discount,
          this.status,
          this.id
        )
        .then((res: any) => {
          Toast.clear()
          this.show = false
          if (res) {
            this.hideItem(this.companyindex)
            this.paymentDays = ''
            this.discount = ''
            this.oilLines = ''
          }
        })
    } else {
      Toast.fail('数据没有填完整！')
    }
  }
  private chexiao() {
    this.paymentDays = ''
    this.discount = ''
    this.oilLines = ''
    this.show = false
  }
  private hideItem(index: number) {
    this.list.splice(index, 1)
  }
  private showCode(): void {
    credit
      .getCreditList(this.code, this.pageNo, 5, this.shipName)
      .then((res: any) => {
        console.log(res)
        if (res && res.list && res.list.length) {
          this.list = this.list.concat(res.list)
        } else {
          this.finished = true
        }

        this.isLoading = false
        this.loading = false

        if (this.isOne && this.list && this.list.length) {
          this.isOne = false
        }
      })
  }
  private onRefresh(): void {
    this.pageNo = 1
    this.finished = false
    this.list = []
    this.isOne = true
    this.showCode()
  }
}
