import Vue from 'vue'
import Vuex from 'vuex'
// const storeApi = require('store')
import storeApi from 'store'
// let defaultPluginsExpire = require('store/plugins/expire')
import defaultPluginsExpire from 'store/plugins/expire'
storeApi.addPlugin(defaultPluginsExpire)
Vue.use(Vuex)
function extime() {
  return new Date().getTime() + Number(21600000) // 6小时 // 259200000 // 1000 * 60 * 60 * 24*3 3天 // 过期时间
}
export default new Vuex.Store({
  state: {
    appInfo: {
      navTitle: '首页',
      rightText: '',
      rightClick: null,
    },
    rpath: '/',
    wxRedirectAuthCode:'', // 微信请求openid code
    userOpenid: storeApi.get('uopenid') || '',
    userToken: storeApi.get('utoken') || '',
    userInfo: storeApi.get('userInfo') || {},
  },
  mutations: {
    SET_APPINFO(state, appinfo) {
      if (!appinfo.rightText) {
        appinfo.rightText = ''
        appinfo.rightClick = null
      }
      state.appInfo = Object.assign(state.appInfo, appinfo)
    },
    SET_USERTOKEN(state, token) {
      state.userToken = token
    },
    SET_RPATH(state, path) {
      state.rpath = path
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_USEROPENID(state, userOpenid) {
      state.userOpenid = userOpenid
    },
    SET_WXREDIRECTAUTHCODE(state,code) {
      state.wxRedirectAuthCode = code
    }
  },
  getters: {
    userToken: (state) => {
      return state.userToken
    },
    wxRedirectAuthCode: state => state.wxRedirectAuthCode,
    rpath: (state) => {
      return state.rpath
    },
    userInfo: (state) => {
      return state.userInfo
    },
    userOpenid: (state) => {
      return state.userOpenid
    }
  },
  actions: {
    setUserToken(context, token) {
      (storeApi as any).set('utoken', token, extime())
      context.commit('SET_USERTOKEN', token)
    },
    setUserInfo(context, userInfo) {
      (storeApi as any).set('userInfo', userInfo, extime())
      context.commit('SET_USERINFO', userInfo)
    },
    setUserOpenid(context, openid) {
      (storeApi as any).set('uopenid', openid, extime())
      context.commit('SET_USEROPENID', openid)
     }
  },
  modules: {},
})
