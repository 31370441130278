<template>
  <div id="app">
    <div id="nav">
      <van-nav-bar
        :title="title"
        :left-text="$router.history.current.path != '/' ? leftText : ''"
        :right-text="rightText"
        border
        :left-arrow="$router.history.current.path != '/'"
        @click-left="leftClick"
        @click-right="rightClick"
      ></van-nav-bar>
    </div>
    <router-view />
  </div>
</template>
<script>
import { NavBar } from 'vant'
export default {
  name: 'App',
  data() {
    return {
      leftText: '返回',
      isRouterAlive: true,
    }
  },
  components: {
    VanNavBar: NavBar,
  },
  computed: {
    title() {
      return this.$store.state.appInfo.navTitle
    },
    rightText() {
      return this.$store.state.appInfo.rightText
    },
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    }
  },
  methods: {
    leftClick() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    rightClick() {
      // console.log('2')
      this.$store.state.appInfo.rightClick &&
        this.$store.state.appInfo.rightClick()
    },

    reload() {
      this.isRouterAlive = false //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true //再打开
      })
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
