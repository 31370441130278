//import axios from "axios";
const axios = (window as any).axios
// const md5 = require('blueimp-md5')
// const md5 = require('../common/utils/md5')
// import * as md5 from 'blueimp-md5'
// const md5 = require('blueimp-md5')
import md5 from 'blueimp-md5'
import { Toast } from 'vant'
const userApi: any = {
  getUserRoleMenu() {
    const url = '/user/getUserRoleMenu'
    return axios.get(url).then((res: any) => {
      if (
        res.data &&
        res.data.menu &&
        res.data.menu.childrenMenu &&
        res.data.menu.childrenMenu.length > 0
      ) {
        let mlist = []
        for (let i = 0; i < res.data.menu.childrenMenu.length; i++) {
          let m = res.data.menu.childrenMenu[i]
          if (m.href == '_mob_back_menu') {
            mlist = m.childrenMenu
            break
          }
        }
        return Promise.resolve(mlist)
      }
      return Promise.reject('暂无权限，请联系管理员分配权限')
    })
  },
  loginOpenid(openid: string) {
    const url = '/user/loginOpenid'
    const data = {
      openid
    }
    return axios
    .get(url, {
      params: data,
    })
    .then((res: any) => {
      if (res.data) {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  getOpenid(code: string, key = 'uMk09d12IO7') {
    const url = '/wx/getOpenidByCode'
    const data = {
      code,
      key
    }
    return axios
    .get(url, {
      params: data,
    })
    .then((res: any) => {
      if (res.data) {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  outLogin(token: string,openid: string) {
    const url = '/user/outLogin'
    const data = {
      sid: token,
      openid: openid
    }
    return axios
    .get(url, {
      params: data,
    })
    .then((res: any) => {
      if (res.data) {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  login(name: string, pwd: string,openid: string) {
    const url = '/user/login'
    const data = {
      password: md5(pwd),
    } as any
    data['login_name'] = name
    if (openid) {
      data['openid'] = openid
    }
    // console.log(pwd, name, url)
    return axios
      .get(url, {
        params: data,
      })
      .then((res: any) => {
        // console.log(res)
        if (res.data) {
          return Promise.resolve(res.data)
        } else {
          Toast(res.data ? res.data.resultMsg : '网络异常')
          return Promise.reject(res.data)
        }
      })
  },
  uppwd(id: string, pwd: string) {
    const url = '/user/addOrUpdateUser'
    const data = {
      id: id,
      password: pwd,
    }
    return axios
      .get(url, {
        params: data,
      })
      .then((res: any) => {
        console.log(res)
        if (res.data) {
          return Promise.resolve(res.data)
        } else {
          Toast(res.data ? res.data.resultMsg : '网络异常')
          return Promise.reject(res.data)
        }
      })
  },
}

export default userApi
