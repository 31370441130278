<template>
  <div className="about">
    <lock-oil-list></lock-oil-list>
  </div>
</template>
<script>
import LockOilList from "../components/LockOilList.vue";

export default {
  name: "About",
  inject: ['reload'],
  data() {
    return {
    }
  },
  created() {
    this.$store.commit("SET_APPINFO", {navTitle: "锁油列表"});
  },
  components: {
    LockOilList: LockOilList
  },
  methods: {

  }
};
</script>
