//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";
const oilArea: any = {
  list(companyId: string, pageNo = 0, pageSize = 10) {
    const url = "/oilArea/oilAreaList";
    const data = {
      companyId,
      pageNo,
      pageSize
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getOilAreaById(id: string) {
    const url = '/oilArea/getOilAreaById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  saveOrUpdateOilArea(name: string, telephone: string,location: string,lipCompanyId: string,remarks = "", id = "") {
    const url = '/oilArea/saveOrUpdateOilArea'
    const data = {
        name,
        telephone,
        location,
        lipCompanyId,
        remarks,
        id
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
};
export default oilArea;
