//import axios from "axios";
const axios = (window as any).axios
import { Toast } from 'vant'

function download(data: any, name: string) {
  if (!data) {
    return
  }
  let blob = new Blob([data], {
    type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  })
  // let URL = window.URL || window.webkitURL
  // let url = URL.createObjectURL(blob)
  // let aLink = document.createElement('a')
  // aLink.style.display = 'none'
  // aLink.href = url
  // aLink.setAttribute('download', name)
  // document.body.appendChild(aLink)
  // aLink.click()
  // document.body.removeChild(aLink) //下载完成移除元素
  // URL.revokeObjectURL(url) //释放掉blob对象
  let newWindow: any = null
  let reader: any = new FileReader()
  reader.onload = function() {
    let bdata = btoa(reader.result)
    let datauri =
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
      bdata
    newWindow = window.open(datauri)
    setTimeout(function() {
      newWindow.document.title = name
    }, 10)
  }
  reader.readAsBinaryString(blob)
}
const oilApi: any = {
  listApply(status: string, type: string, pageNo: string, pageSize: string,shipName='') {
    const url = '/oil/queryOilApplyBeans'
    const data = {
      status,
      pageNo,
      pageSize,
      type,
      shipName,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  /**
   *  生成对账单
   * @param applyIds 加油计划ids 1,2,3
   * @param type 对账单类型 1 用户/公司对账 2 加油站对账
   */
  saveBillBean(applyIds: string, type: 1 | 2) {
    const url = '/oil/saveBillBean'
    const data = {
      applyIds,
      type,
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  /**
   * 对账单结算
   * @param oilBillId 对账单id
   * @param oilSubBillIds 对账单明细ids 1,2,3
   */
  settleBill(oilBillId: string, oilSubBillIds: string) {
    const url = '/oil/settleBill'
    const data = {
      oilSubBillIds,
      oilBillId,
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  /**
   * 查询对账单列表
   * @param status 状态 1 未结算 2 已结算
   * @param type  类型 1 用户对账 2 加油站对账
   * @param pageNo 1
   * @param pageSize 5
   */
  queryOilBill(status: string, type: 1 | 2, pageNo = 1, pageSize = 5) {
    const url = '/oil/queryOilBill'
    const data = {
      status,
      type,
      pageSize,
      pageNo,
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  /**
   * 查询对账单明细
   * @param oilBillId 对账单id
   */
  queryOilSubBill(oilBillId: string) {
    const url = '/oil/queryOilSubBill'
    const data = {
      oilBillId,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  /**
   * 加油计划列表-结算对账
   * @param status 4 加油计划完成
   * @param type 加油计划类型 帐期 payment  or 现金 cash
   * @param userStatus 用户对账状态查询
   * @param oilStatus 加油对账状态 查询
   * @param userName 按用户名查询
   * @param companyName 按公司名查询
   */
  queryOilApplyByDoc(
    status: string,
    type: string,
    userStatus = '',
    oilStatus = '',
    userName = '',
    companyName = ''
  ) {
    const url = '/oil/queryOilApplyByDoc'
    const data = {
      status,
      type,
      companyName,
      userName,
      oilStatus,
      userStatus,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  applyPrice(applyid: string) {
    const url = '/oil/applyPrice'
    const data = {
      applyid,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  updateApply(id: string, status: string, statusDesc = '') {
    const url = '/oil/updataApply'
    const data = {
      status,
      id,
      statusDesc,
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  upOilNumberPrice(id: string, salePrice: string, price: string) {
    const url = '/oil/upOilNumberPrice'
    const data = {
      salePrice,
      price,
      id,
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  queryGoodsPage(type: string, pageNum = 1, pageSize = 10) {
    const url = '/goods/queryGoodsPage'
    const data = {
      type,
      pageNum,
      pageSize,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  saveOrUpdateGoods(name: string, type: string, remarks = '', id = '', url: string,delFlag: string) {
    const urls= '/goods/saveOrUpdateGoods'
    const data = {
      name,
      type,
      remarks,
      url,
      delFlag
    } as any
    if (id) {
      data.id = id
    }
    return axios.get(urls, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  getGoodsById(id: string) {
    const url = '/goods/getGoodsById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  queryPricePage(
    goodsId: string,
    companyId = '',
    pageNum = 1,
    pageSize = 10,
    start = ''
  ) {
    const url = '/price/queryPricePage'
    const data = {
      goodsId,
      companyId,
      pageNum,
      pageSize,
      start,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  getPriceById(id: string) {
    const url = '/price/getPriceById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  saveOrUpdatePrice(
    id: string,
    price: string,
    unit: string,
    date: string,
    goodsId: string,
    salePrice: string,
    companyId: string
  ) {
    const url = '/price/saveOrUpdatePrice'
    const data = {
      id,
      price,
      unit,
      date,
      goodsId,
      salePrice,
      companyId,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  deletePriceById(id: string) {
    const url = '/price/deletePriceById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  downoil(id: string, name: string) {
    const url = '/oil/download'
    return axios
      .get(url, { params: { oilId: id }, responseType: 'blob' })
      .then((res: any) => {
        console.log(res)
        download(res.data, name + '加油计划.xlsx')
      })
  },
}

export default oilApi
