//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";
const oilStation: any = {
  list(oilAreaId: string, pageNo = 0, pageSize = 10) {
    const url = "/oilstation/oilstationList";
    const data = {
      oilAreaId,
      pageNo,
      pageSize
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getOilstationById(id: string) {
    const url = '/oilstation/getOilstationById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  saveOrUpdateOilstation(name: string, phone: string,location: string,
    oilAreaId: string,longitude: number ,latitude: number,companyId: string,remarks = "", id = "") {
    const url = '/oilstation/saveOrUpdateOilstation'
    const data = {
        name,
        phone,
        location,
        oilAreaId,
        longitude,
        latitude,
        companyId,
        remarks,
        id
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
};
export default oilStation;
