










































import { Component, Prop, Vue } from 'vue-property-decorator'
// import { formatTimeYear } from '../common/utils/utils'
import { Cell, CellGroup, SwipeCell, Button, List } from 'vant'
@Component({
  name: 'OilGoodsList',
  components: {
    VanSwipeCell: SwipeCell,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanButton: Button,
    VanList: List,
  },
})
export default class OilGoodsList extends Vue {
  @Prop() private type!: string
  @Prop() private list!: Array<Record<string, any>>
  private loading = false
  private finished = false
  private timeDate(date: string): string {
    // return formatTimeYear(new Date(date.substr(0, 10).replace('-', '/')), '-')
    return date.substr(0, 10)
  }
   private flag(delFlag: string): string {
    
    return delFlag=='0'?'可用':'不可用'
  }
  private openEdit(id: string): void {
    // console.log(id)
    this.$emit('edit', id)
  }
  private encode(str: string): string {
    // console.log(str, encodeURIComponent(str))
    return encodeURIComponent(str)
  }
  private onLoad(): void {
    this.$emit('load', (fin: boolean, type: string) => {
      // console.log('load', fin, this.type, type)
      this.loading = false
      if (fin && this.type == type) {
        this.finished = true
      }
    })
  }
  private onone(): void {
    this.finished = false
  }
}
