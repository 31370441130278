<template>
  <div class="about">
        <pub-message-list ></pub-message-list>     
  </div>
</template>
<script>
import PubMessageList from "../components/PubMessageList.vue";
export default {
  name: "About",
  inject:['reload'],
  created() {
    this.$store.commit("SET_APPINFO", { navTitle: "消息推送" });
  },
  components: {
    PubMessageList: PubMessageList
  },
  methods: {
    //tabChange(title, name) {
      // console.log(title, name)
    //}
  }
};
</script>
