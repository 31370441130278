<template>
  <div>
        <oil-company-list
          :list="list"
          @edit="editCompany"
          @load="onload"
        ></oil-company-list>
    <van-popup
      v-model="show"
      @closed="closePop"
      closeable
      position="bottom"
      class="pop-cls"
      get-container="body"
    >
      <van-loading type="spinner" v-show="popload" />
      <van-cell-group v-if="!popload">
        <van-field
          v-model="editInfo.companyName"
          required
          label="企业名称"
          placeholder="请输入名称"
        />
       <van-field
          v-model="editInfo.abbreviation"
          required
          label="企业简称"
          placeholder="请输入简称"
        />
        <van-field
          v-model="editInfo.remarks"
          label="备注"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入备注"
        />
        <div style="margin: 16px;">
          <van-button
            :loading="btnDis"
            :disabled="btnDis"
            round
            block
            type="info"
            @click="subBtn"
          >
            提交
          </van-button>
        </div>
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import OilCompanyList from '../components/OilCompanyList.vue'
import {
  Loading,
  Popup,
  Field,
  CellGroup,
  Button,
  Toast
} from 'vant'
import company from '../api/company'
export default {
  components: {
    VanPopup: Popup,
    VanLoading: Loading,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanButton: Button,
    OilCompanyList: OilCompanyList,
  },
  data() {
    return {
      show: false,
      popload: false,
      btnDis: false,
      editInfo: {
        id: '',
        companyName: '',
        abbreviation: '',
        remarks: '',
      },
      defIndx: '',
      active: '',
      tabList: [],
      list: [],
      showPicker: false,
      load: true,
    }
  },
  pageNo: 1,
  pageSize: 10,
  created() {
    this.$store.commit('SET_APPINFO', {
      navTitle: '加油企业管理',
      rightText: '新增',
      rightClick: this.addBtn,
    })
    this.loadData()
  },
  methods: {
    onload(callback) {
      //   console.log('onload')
      this.nextlist(callback)
    },
    nextlist(callback) {
      this.pageNo++
      //   console.log('type', this.active)
      company
        .list('', this.pageNo, this.pageSize, null, "param2")
        .then((res) => {
          if (res) {
            this.list = this.list.concat(res.list)
          }
          callback &&
            callback(res==null||res == 0)
        })
    },
    editCompany(id) {
      console.log(id)
      this.showPop()
      this.popload = true
      company.getCompanyById(id).then((item) => {
        this.editInfo.id = item.id
        this.editInfo.companyName = item.companyName
        this.editInfo.abbreviation = item.abbreviation
        this.editInfo.remarks = item.remarks
        this.popload = false
      })
    },
    addBtn() {
      this.editInfo.id = ''
      this.editInfo.companyName = ''
      this.editInfo.abbreviation = ''
      this.editInfo.remarks = ''
      this.showPop()
    },
    showPop() {
      this.show = true
      this.saveStatus = false
    },
    subBtn() {
      this.btnDis = true
      if (this.editInfo.companyName && this.editInfo.abbreviation) {
        // console.log('sub', this.editInfo)
        company
          .saveOrUpdatecompany(
            this.editInfo.companyName,
            this.editInfo.abbreviation,
            this.editInfo.remarks,
            this.editInfo.id
          )
          .then((res) => {
            this.show = false
            // console.log(res)
            this.saveStatus = true
          })
          .finally(() => {
            setTimeout(() => {
              this.btnDis = false
            }, 300)
          })
        return
      }
      Toast('请填写信息')
    },
    closePop() {
      if (this.saveStatus) {
        this.changeTab()
      }
    },
    changeTab() {
      this.loadData()
    },
    loadData() {
      this.list = []
      this.load = true
      this.pageNo = 1
      company.list(null, this.pageNo, this.pageSize, null, 'param2').then((res) => {
        this.list = res.list
        this.load = false
      })
    },
  },
}
</script>

<style scoped lang="scss">
.pop-cls {
  height: 35%;
  padding-top: 50px;
}
</style>
