//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";
const notice: any = {
    list(pageNum = 0, pageSize = 10,name='') {
        const url = "/pagenotice/queryPageNoticeList";
        const data = {
            status,
            pageNum,
            pageSize,
            name
        };
        return axios.get(url, { params: data }).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    noticeStatus(id: string, isopen: string) {
        const url = "/pagenotice/noticeStatus";
        const data = {
            id,
            isopen
        };
        return axios.post(url, data).then((res: any) => {
            if (res.data && res.data.resultCode === "0") {
                return Promise.resolve(res.data.data);
            } else {
                Toast(res.data ? res.data.resultMsg : "网络异常");
                return Promise.reject(res.data);
            }
        });
    },
    getPageNoticeById(id: string) {
        const url = '/pagenotice/queryDetailById'
        const data = {
            id,
        }
        return axios.get(url, { params: data }).then((res: any) => {
            if (res.data && res.data.resultCode === '0') {
                return Promise.resolve(res.data.data)
            } else {
                Toast(res.data ? res.data.resultMsg : '网络异常')
                return Promise.reject(res.data)
            }
        })
    },
    saveOrUpdateNotice(name: string, content: string, isopen: string, id = "") {
        const url = '/pagenotice/saveOrUpdateNotice'
        const data = {
            name,
            content,
            isopen,
            id
        }
        return axios.post(url, data).then((res: any) => {
            if (res.data && res.data.resultCode === '0') {
                return Promise.resolve(res.data)
            } else {
                Toast(res.data ? res.data.resultMsg : '网络异常')
                return Promise.reject(res.data)
            }
        })
    },
};
export default notice;
