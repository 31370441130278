import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import OilChk from '../views/OilChk.vue'
import OilDoc from '../views/OilDoc.vue'
import store from '../store'
import credit from '../views/credit.vue'
import OilGoods from '../views/OilGoods.vue'
import PubMessage from '../views/PubMseeage.vue'
import OilPrice from '../views/OilPrice.vue'
import OilCompany from '../views/OilCompany.vue'
import OilArea from '../views/OilArea.vue'
import OilStation from '../views/OilStation.vue'
import Uppwd from '../views/Uppwd.vue'
import OilBill from '../views/OilBill.vue'
import SysDetail from '../views/Sysdetil.vue'
import PageNotice from '../views/PageNotice.vue'
import LockOil from "../views/LockOil.vue"
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sysdetail',
    name: 'Sysdetail',
    component: SysDetail,
  },
  {
    path: '/oilchk',
    name: 'OilChk',
    component: OilChk,
  },
  {
    path: '/oildoc',
    name: 'OilDoc',
    component: OilDoc,
  },
  {
    path: '/OilCompany',
    name: 'OilCompany',
    component: OilCompany,
  },
  {
    path: '/pageNotice',
    name: 'PageNotice',
    component: PageNotice
  },
  {
    path: '/oilArea',
    name: 'OilArea',
    component: OilArea,
  },
  {
    path: '/oilBill',
    name: 'OilBill',
    component: OilBill,
  },
  {
    path: '/oilStation',
    name: 'OilStation',
    component: OilStation,
  },
  {
    path: '/oilgoods',
    name: 'OilGoods',
    component: OilGoods,
  },
  {
    path: '/oilgoodsprice',
    name: 'OilPrice',
    component: OilPrice,
  },
  {
    path: '/credit',
    name: 'credit',
    component: credit,
  },
  {
    path: '/pubmessage',
    name: 'PubMessage',
    component: PubMessage,
  },
  {
    path: '/uppwd',
    name: 'Uppwd',
    component:Uppwd,
  },
  {
    path: '/lockOil',
    name: 'LockOil',
    component: LockOil
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/realNameChk',
    name: 'RealNameChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/RealNameChk.vue'),
  },
  {
    path: '/shipChk',
    name: 'ShipChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipChk.vue'),
  },
  {
    path: '/shipStandChk',
    name: 'ShipStandChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipStandChk.vue'),
  },
  {
    path: '/shipStandDetail',
    name: 'ShipStandDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipStandDetail.vue'),
  },
  {
    path: '/shipRzChk',
    name: 'ShipRzChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipRzChk.vue'),
  },
  {
    path: '/shipRzDetail',
    name: 'ShipRzDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipRzDetail.vue'),
  },
  {
    path: '/shipPlateChk',
    name: 'ShipPlateChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShipPlateChk.vue'),
  },
  {
    path: '/plateChk',
    name: 'PlateChk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PlateChk.vue'),
  },
  {
    path: '/shipBase',
    name: 'shipBase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ '../views/ShipBase.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  // console.log(store.getters.userToken)
  if (to.path == '/login') {
    // console.log(to)
    next()
  } else if (store.getters.userToken) {
    next()
  } else {
    // 记录跳转地址
    store.commit('SET_RPATH', to.fullPath)
    next({ name: 'Login' })
  }
})

export default router
