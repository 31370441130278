//import axios from "axios";
const axios= (window as any).axios
import { Toast } from "vant";
const company: any = {
  list(status: string, pageNo = 0, pageSize = 10,companyName='',param2='') {
    const url = "/authenaudit/selectListByObjCompany";
    const data = {
      status,
      pageNo,
      pageSize,
      companyName,
      param2
    };
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.page);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  companyStatus(id: string, status: string, statusDecs = "", createBy = "") {
    const url = "/company/companyStatus";
    const data = {
      id,
      status,
      statusDecs,
      createBy
    };
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === "0") {
        return Promise.resolve(res.data.data);
      } else {
        Toast(res.data ? res.data.resultMsg : "网络异常");
        return Promise.reject(res.data);
      }
    });
  },
  getCompanyById(id: string) {
    const url = '/company/getLipCompanyById'
    const data = {
      id,
    }
    return axios.get(url, { params: data }).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  saveOrUpdatecompany(companyName: string, abbreviation: string,remarks = "", id = "",param2="1",status="3") {
    const url = '/company/saveOrUpdatecompany'
    const data = {
      companyName,
      abbreviation,
      remarks,
      param2,
      status,
      id
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
  updateCompany(id: string, showFlag: string) {
    const url = '/company/updateCompany'
    const data = {
      showFlag,
      id
    }
    return axios.post(url, data).then((res: any) => {
      if (res.data && res.data.resultCode === '0') {
        return Promise.resolve(res.data)
      } else {
        Toast(res.data ? res.data.resultMsg : '网络异常')
        return Promise.reject(res.data)
      }
    })
  },
};
export default company;
