<template>
  <div>
    <van-tabs v-model="active" @change="changeTab" color="#4CD5A6">
      <van-tab
        v-for="item in tabList"
        :key="item.id"
        :name="item.id"
        :title="item.companyName"
      >
        <van-cell
          :title="$route.query.goodsName"
          @click="addBtn"
          is-link
          value="价格维护"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell-group
            v-for="(subitem, index) in list"
            :key="subitem.id"
            :title="subitem.start"
            :border="borderc"
          >
            <van-cell
              title-class="cel-cls"
              value-class="val-cls"
              title="采购价格(元)"
              :value="subitem.price"
            />
            <van-cell
              title-class="cel-cls"
              value-class="val-cls"
              title="出售价格(元)"
              :value="subitem.salePrice"
            />
            <van-cell
              title-class="cel-cls"
              value-class="val-cls"
              title="单位"
              :value="subitem.unit"
            />
            <van-cell
              title-class="cel-cls"
              value-class="val-cls"
              title="开始时间"
              :value="subitem.start"
            />
            <van-cell
              title-class="cel-cls"
              value-class="val-cls"
              title="结束时间"
              :value="subitem.end || ''"
            />
            <van-button
              v-if="index == 0"
              type="primary"
              @click="showPop(subitem.id)"
              >编辑</van-button
            >
            <van-button
              v-if="index == 0"
              type="danger"
              @click="delPop(subitem.id)"
              >删除</van-button
            >
          </van-cell-group>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="show"
      closeable
      @closed="closePop"
      position="bottom"
      class="pop-cls"
      get-container="body"
    >
      <van-loading type="spinner" v-show="popload" />
      <van-cell-group v-if="!popload">
        <van-field
          v-model="editInfo.price"
          required
          label="采购价格"
          placeholder="请输入采购价格"
        ></van-field>
        <van-field
          v-model="editInfo.salePrice"
          required
          label="出售价格"
          placeholder="请输入出售价格"
        ></van-field>
        <van-field
          v-model="editInfo.unit"
          required
          label="单位"
          placeholder="请输入单位"
        ></van-field>
        <van-field
          :value="editInfo.start"
          required
          readonly
          clickable
          label="开始时间"
          @click="showTime = true"
          placeholder="请选择开始时间"
        ></van-field>
        <van-popup v-model="showTime" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            @confirm="confirm"
            @cancel="cancel"
            type="datetime"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
        <van-button
          :loading="subLoad"
          :disabled="subLoad"
          type="info"
          @click="subBtn"
          >提交</van-button
        >
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  Cell,
  CellGroup,
  List,
  Button,
  Popup,
  Field,
  Toast,
  Dialog,
  DatetimePicker,
  Loading,
} from 'vant'
import oilApi from '../api/oil'
import { formatTime } from '../common/utils/utils'
export default {
  data() {
    return {
      editInfo: {
        id: '',
        price: '',
        salePrice: '',
        unit: '',
        start: '',
      },
      popload: false,
      tabList: [],
      list: [],
      show: false,
      subLoad: false,
      showTime: false,
      loading: false,
      finished: false,
      active: '',
      minDate: new Date(),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      borderc: false,
    }
  },
  components: {
    VanList: List,
    VanButton: Button,
    VanTab: Tab,
    VanPopup: Popup,
    VanLoading: Loading,
    VanField: Field,
    VanDatetimePicker: DatetimePicker,
    VanTabs: Tabs,
    VanCellGroup: CellGroup,
    VanCell: Cell,
  },
  pageNum: 1,
  pageSize: 10,
  created() {
    // console.log(this.$route.query.goodsId)
    if (!this.$route.query.goodsId) {
      Toast('参数错误')
      return
    }
    this.$store.commit('SET_APPINFO', {
      navTitle: this.$route.query.goodsName || '价格管理',
      rightText: '价格',
      rightClick: this.addBtn,
    })
    this.refLoad()
  },
  methods: {
    closePop() {
      if (this.saveStatus) {
        this.refLoad()
      }
    },
    delPop(id) {
      Dialog.confirm({
        title: '删除该价格',
        message: '是否确认',
      }).then(() => {
        Toast.loading({
          message: '删除中...',
          forbidClick: true,
        })
        oilApi
          .deletePriceById(id)
          .then(() => {
            this.refLoad()
          })
          .finally(() => {
            Toast.clear()
          })
      })
    },
    addBtn() {
      this.editInfo = {
        id: '',
        price: '',
        salePrice: '',
        unit: '',
        start: '',
      }
      this.showBigPop()
    },
    showBigPop() {
      this.saveStatus = false
      this.show = true
    },
    cancel() {
      this.showTime = false
    },
    confirm(value) {
      //   console.log(value)
      this.editInfo.start = formatTime(value, '-')
      this.showTime = false
    },
    showPop(id) {
      this.popload = true
      this.showBigPop()
      oilApi.getPriceById(id).then((res) => {
        this.editInfo = res
        this.popload = false
      })
    },
    subBtn() {
      if (
        this.editInfo.price &&
        this.editInfo.unit &&
        this.editInfo.start &&
        this.editInfo.salePrice
      ) {
        this.subLoad = true
        oilApi
          .saveOrUpdatePrice(
            this.editInfo.id,
            this.editInfo.price,
            this.editInfo.unit,
            this.editInfo.start,
            this.$route.query.goodsId,
            this.editInfo.salePrice,
            this.active
          )
          .then((res) => {
            // console.log(res)
            this.show = false

            this.saveStatus = true
          })
          .finally(() => {
            this.subLoad = false
          })
        return
      }
      Toast('请填写信息')
    },
    refLoad() {
      this.pageNum = 1
      this.list = []
      this.finished = false
      this.loadData()
    },
    onLoad() {
      this.pageNum++
      this.loadData()
    },
    loadData() {
      oilApi
        .queryPricePage(
          this.$route.query.goodsId,
          this.active,
          this.pageNum,
          this.pageSize
        )
        .then((res) => {
          //   console.log(res)
          this.tabList = res.list
          if (res.page && res.page.list) {
            this.list = this.list.concat(res.page.list)
          } else {
            this.finished = true
          }
          this.loading = false
        })
    },
    changeTab() {
      //   console.log(this.active)
      this.refLoad()
    },
  },
}
</script>

<style scoped lang="scss">
.cel-cls,
.van-cell-group__title {
  text-align: left;
}
.pop-cls {
  height: 35%;
  padding-top: 50px;
}
</style>
