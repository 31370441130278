<template>
  <div>
    <van-tabs
      v-model="active"
      sticky
      animated
      swipeable
      color="#4CD5A6"
    >
      <van-tab title="待对账" :name="type == 'user' ? 's:4,u:0' : 's:4,o:0'">
        <oil-doc-list :code="active"></oil-doc-list>
      </van-tab>
      <van-tab title="对账单" :name="type == 'user' ? 's:4,u:1' : 's:4,o:1'">
        <bill-chk-list :code="active"></bill-chk-list>
      </van-tab>
      <van-tab title="已结算" :name="type == 'user' ? 's:4,u:2' : 's:4,o:2'">
        <bill-chk-list :code="active"></bill-chk-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from "vant";
import OilDocList from "../components/OilDocList";
import BillChkList from "@/components/BillChkList.vue";

export default {
  data() {
    return {
      type: "user",
      active: "s:4,u:0" // s status ,u userstatus 企业账单状态，o oilstatus 加油站账单状态
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.active = "s:4,o:0";
      this.$store.commit("SET_APPINFO", { navTitle: "加油站帐单" });
      return;
    }
    this.$store.commit("SET_APPINFO", { navTitle: "加油单帐" });
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    OilDocList: OilDocList,
    BillChkList: BillChkList
  },
  methods: {}
};
</script>

<style></style>
